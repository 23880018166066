import http from "./httpService";
import floatFormatter from '../components/Custom/floatFormatter.js'
import {fieldTypeContact} from "../config/contact/fieldTypeContact.js"
// Import Endpoints
import {
    contactUrl,
    contactDdUrlV1,
    contactDetail,
    contactForm,
    contactMassEdit,
    contactKanbanUrl,
    tagsUrl,
    contactVendor,
    contactHistory,
    contactMassDelete,
    contactShort
} from '../config/Endpoints';
import {toast} from "react-toastify";


const getVendors=async(source)=>{
    try {
        const {data}=await http.get(contactVendor,{ cancelToken: source.token })
        return data
    } catch (error) {
        console.log(error)
        return []
    }

}
const getVendor=async(id)=>{
    try {
        const {data}=await http.get(`${contactVendor}${id}`)
        return data
    } catch (error) {
        console.log(error)
        return []
    }

}
const getContacts=async(query="",source)=>{
    // I made this do the sort url, might need to undo this
    try {
        const {data}=await http.get(`${contactUrl}`+query,{ cancelToken: source?.token })
        const parsedList = floatFormatter(data, fieldTypeContact)
        return parsedList
    } catch (error) {
        console.log(error)
    }

}

const getContactsv1=async(query="",source)=>{
    try {
        const {data}=await http.get(contactDdUrlV1+query,{ cancelToken: source?.token })
        const parsedList = floatFormatter(data, fieldTypeContact)
        return parsedList
        // return data
    } catch (error) {
        console.log(error)
    }

}

const getContactShort = async (source,query="") => {
    try {

        let response;
            response = await http.get(contactShort+"?ordering=first_name&&" + query, { cancelToken: source?.token });


        const data = response.data; // Assuming that the response object has a data property
               // console.log({contactShort},data)

        const parsedList = floatFormatter(data, fieldTypeContact);
        return parsedList;
    } catch (error) {
        console.log(error);
    }
};





const massEdit=async(id,source)=>{
    try {
        const data=await http.post(contactMassEdit,id,{ cancelToken: source?.token })
        toast.success('Contacts Updated Successfully')
        return data
        // return parsedList
    } catch (error) {
        toast.error('Contact Update Failed'+ error.response.request.responseText)
        console.log(error)
    }

}



const deleteContacts=async(id,source)=>{
    try {
        const data={
            contact_ids: id
        }
        await http.post(contactMassDelete,data,{ cancelToken: source?.token })
        toast.warning('Contact Deleted Successfully')
        // return parsedList
    } catch (error) {
        toast.error('Contact Delete Failed'+ error.response.request.responseText)
        console.log(error)
        return error

    }

}



const getContact=async(id,source)=>{
    if(!id) return {}
    try {
        const {data}=await http.get(`${contactDetail}${id}`,{ cancelToken: source?.token })
        const parsedList = floatFormatter(data, fieldTypeContact)
        return parsedList
    } catch (error) {
        console.log(error)
        return {}
    }

}

const updateContact=async (id,contact)=>{
    try {
        const {data}= await http.put(`${contactForm}${id}/`,contact)
        toast.success('Contact Updated Successfully')
        return data
    } catch (error) {
        toast.error('Contact Update Failed'+ error.response.request.responseText)
        console.log(error)
        return error
    }
}

const updateContactPatch=async (id,contact)=>{
    try {
        const {data}= await http.patch(`${contactForm}${id}/`,contact)
        toast.success('Contact Updated Successfully')
        return data
    } catch (error) {
        toast.error('Contact Update Failed'+ error.response.request.responseText)
        console.log(error)
        return error
    }
}


const getTags=async(source)=>{
    try {
        const {data}= await http.get(`${tagsUrl}`,{ cancelToken: source.token })
        return data
    } catch (error) {
        console.log(error)
        return []
    }

}

const getContactsKanban=async(source)=>{
    try {
        const {data}=await http.get(`${contactKanbanUrl}`,{ cancelToken: source.token })
        return data
    } catch (error) {
        console.error(error)

    }
}

const updateTags=async(id,body)=>{
    try {
        let {data}= await http.put(`${contactUrl}${id}/`,body)
        toast.success('Tags Updated Successfully')
        return data
    } catch (error) {
        toast.error('Tags Update Failed'+ error.response.request.responseText)
        console.log(error)
        return error
    }

}


const createContact = async(contact) => {
    try {
        const {data}=await http.post(`${contactForm}`, contact)
        toast.success('Contact Created Successfully')
        return data
    } catch (error) {
        // return error
        toast.error('Contact Creation Failed'+ error.response.request.responseText)
        const errorMessage=error.response?.data

        if(!errorMessage) return false

        return false
    }
}

const getOptions = async(source) => {
    try {
        const {data}=await http.options(`${contactUrl}`,{ cancelToken: source.token,...http.tokenConfig() })
        // console.log("getOptions",data)
        return data
    } catch (error) {
        console.log(error)
    }
}


const getContactChanges=async(id,source)=>{
    if(!id) return {}
    try {
        const {data}=await http.get(`${contactHistory}${id}`,{ cancelToken: source?.token })
        return data
    } catch (error) {
        console.log(error)
        return {}
    }

}

export default {
    getContacts,
    getContact,
    updateContact,
    getContactsKanban,
    getContactShort,
    getTags,
    updateTags,
    createContact,
    getOptions,
    getVendors,
    getVendor,
    getContactChanges,
    deleteContacts,
    massEdit,
    getContactsv1,
    updateContactPatch
}