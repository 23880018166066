import {
   getData,
   getDropdownData,
   getManyData, getManyFilteredData
} from '../../endpointService';

import {
   addressDDUrlV1,
   addressDdUrlV1
} from '../../../config/Endpoints';

export const getAddressList = async () => {
   try {
      const res = await getManyData(addressDDUrlV1)
      return res[0]
   } catch (err) {
      console.log(err)
      return {}
   }
}

export const getAddressDropdown = async () => {
   try {
      return await getDropdownData(addressDdUrlV1)
   } catch (err) {
      console.log(err)
   }
}

export const getFilteredAddresses = async (filters) => {
   try {
      const res = await getManyFilteredData(addressDDUrlV1, filters)
      return res[0]
   } catch (err) {
      console.log(err)
   }
}

export const getAddressDetail = async (id) => {
   try {
      const res = await getData(addressDDUrlV1, id)
      return res.data
   } catch (err) {
      console.log(err)
   }
}