import http from "./httpService";

// Import Endpoints
import {linkTokenUrl,accessTokenUrl } from '../config/Endpoints';
import {toast} from "react-toastify";
const getToken=async(obj)=>{
    try{
     const {data}=await http.post(linkTokenUrl,obj)
     return data
    }
    catch(ex){
        toast.error('Error Getting Token'+ ex.message);
        console.log({ex})

    }

}



const storeToken=async(obj)=>{
    try{
     const {data}=await http.post(accessTokenUrl,obj)
     return data
    }
    catch(ex){
        console.log({ex})

    }

}

export default {
    getToken,
    storeToken
}