import React from 'react';
import { useDispatch } from 'react-redux';
import { Row, Col, Button, Alert } from 'react-bootstrap';
import {Link, Redirect, useHistory, useLocation} from 'react-router-dom';

import * as Yup from 'yup';
import { Formik } from 'formik';
import axios from 'axios';
import useScriptRef from '../../../hooks/useScriptRef';
import { API_SERVER } from '../../../config/constant';
import { ACCOUNT_INITIALIZE } from '../../../store/actions';

const RestLogin = ({ className, ...rest }) => {
   const dispatcher = useDispatch();
   const scriptedRef = useScriptRef();
   let history = useHistory();

   const emailRegex = /[\w.-]+@equityandhelp\.com/;
   let emailSchema = Yup.string().matches(emailRegex, 'Email Address Must End In @equityandhelp.com');
   // console.log({ location });
   return (
      <React.Fragment>
         <Formik
            initialValues={{
               email: '',
               password: '',
               submit: null,
            }}
            validationSchema={Yup.object().shape({
               // email: Yup.string()
               //    .email('Must be a valid email')
               //    .max(255)
               //    .required('Email is required'),
               email: emailSchema,
               password: Yup.string().max(255).required('Password is required'),
            })}
            onSubmit={async (
               values,
               { setErrors, setStatus, setSubmitting }
            ) => {
               try {
                  axios
                     .post(API_SERVER + 'api/v1/login/', {
                        password: values.password,
                        email: values.email,
                     })
                     .then(function (response) {
                        // console.log('What we got', response.data);
                        if (response.data.success) {
                           // console.log('what we ');

                           dispatcher({
                              type: ACCOUNT_INITIALIZE,
                              payload: {
                                 isLoggedIn: true,
                                 user: response.data,
                                 token: response.data.token,
                              },
                           });

                           if (scriptedRef.current) {
                              setStatus({ success: true });
                              setSubmitting(false);
                              const nextUrl = history.location.search;
                              const redirectUrl =
                                 nextUrl.replace('?', '/') || '/admin/dashboard';

                              // console.log('redirect url iis >>>>', redirectUrl);
                              history.push(redirectUrl);
                           }
                        } else {
                           setStatus({ success: false });
                           setErrors({
                              submit: response.data.non_field_errors,
                           });
                           setSubmitting(false);
                        }
                     })
                     .catch(function (error) {
                        // console.log("what we 1")
                        //going here when wrong creds

                        // console.log(error);
                        setStatus({ success: false });
                        setErrors({
                           submit: error.response?.data?.non_field_errors,
                        });
                        setSubmitting(false);
                     });
               } catch (err) {
                  console.error(err);
                  if (scriptedRef.current) {
                     setStatus({ success: false });
                     setErrors({ submit: err.message });
                     setSubmitting(false);
                  }
               }
            }}
         >
            {({
               errors,
               handleBlur,
               handleChange,
               handleSubmit,
               isSubmitting,
               touched,
               values,
            }) => (
               <form
                  noValidate
                  onSubmit={handleSubmit}
                  className={className}
                  {...rest}
               >
                  <div className="form-group mb-3">
                     <input
                        className="site-input form-control"
                        error={touched.email && errors.email}
                        label="Email Address"
                        placeholder="Email Address"
                        name="email"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="email"
                        value={values.email}
                     />
                     {touched.email && errors.email && (
                        <small className="text-danger form-text">
                           {errors.email}
                           <Link className="btn btn-sm btn-primary text-white" to="signin-2"> Click Here To Go To Family Login </Link>
                        </small>
                     )}
                  </div>
                  <div className="form-group mb-4">
                     <input
                        className="site-input form-control"
                        error={touched.password && errors.password}
                        label="Password"
                        placeholder="Password"
                        name="password"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="password"
                        value={values.password}
                     />
                     {touched.password && errors.password && (
                        <small className="text-danger form-text">
                           {errors.password}
                        </small>
                     )}
                  </div>

                  {errors.submit && (
                     <Col sm={12}>
                        <Alert variant="danger">{errors.submit}</Alert>
                     </Col>
                  )}

                  <Row>
                     <Col mt={2}>
                        <Button
                           className="btn-block"
                           disabled={isSubmitting}
                           size="large"
                           type="submit"
                           variant={!isSubmitting ? 'primary' : 'secondary'}
                        >
                           Login
                        </Button>
                     </Col>
                  </Row>
               </form>
            )}
         </Formik>
         <hr />
      </React.Fragment>
   );
};

export default RestLogin;
