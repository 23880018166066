import React, { useState, useEffect } from 'react';
import { ListGroup, Link } from 'react-bootstrap';
import { useLocation, useParams, NavLink } from 'react-router-dom';

import navigation from '../../../menu-items';
import { BASE_TITLE, BASENAME } from '../../../config/constant';

const Breadcrumb = () => {
    const [main, setMain] = useState(null);
    const [item, setItem] = useState(null);
    const location = useLocation();
    const { id } = useParams();

    useEffect(() => {
        navigation.items.forEach((item) => {
                // console.log("nav",{item})

            if (item.type === 'group') {
                getCollapse(item);
            }
        });
    }, [location]);

    const getCollapse = (item) => {
        if (item.children) {
            item.children.forEach((collapse) => {
                if (collapse.type === 'collapse') {
                    getCollapse(collapse);
                } else if (collapse.type === 'item') {
                    const fullUrl = BASENAME + collapse?.url?.replace(':id', id || '');
                    if (location.pathname === fullUrl) {
                        setMain(item);
                        setItem(collapse);
                    }
                }
            });
        }
    };

    let breadcrumbContent = '';
    let title = item?.title || '';

    if (item && item.breadcrumbs !== false) {
        breadcrumbContent = (
            <div className="page-header breadcrumb-b">
                <div className="page-block">
                    <div className="row align-items-center">
                        <div className="col-md-12">
                            <div className="page-header-title">
                                <h5 className="m-b-10">{title}</h5>
                            </div>
                            <ListGroup as="ul" bsPrefix=" " className="breadcrumb">
                                <ListGroup.Item as="li" bsPrefix=" " className="breadcrumb-item">
                                    <NavLink to={`${BASENAME}/`}>
                                        <i className="feather icon-home" />
                                    </NavLink>
                                </ListGroup.Item>
                                {main && (
                                    <ListGroup.Item as="li" bsPrefix=" " className="breadcrumb-item">
                                        <NavLink to={`${BASENAME}${main.url}`}>{main.title}</NavLink>
                                    </ListGroup.Item>
                                )}
                                <ListGroup.Item as="li" bsPrefix=" " className="breadcrumb-item active">
                                    {title}
                                </ListGroup.Item>
                            </ListGroup>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    useEffect(() => {
        document.title = title ? `${title} - ${BASE_TITLE}` : BASE_TITLE;
    }, [title]);

    return <>{breadcrumbContent}</>;
};

export default Breadcrumb;
