import React, { useState, useEffect } from 'react';
import { Row, Col, Button, Form, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import {Addresses} from '../../../services'
import { GOOGLE_MAP_API_KEY } from '../../../config/constant';
import Geocode from 'react-geocode';
import Autocomplete from "react-google-autocomplete";

import httpService from '../../../services/httpService';

import { useDispatch} from 'react-redux';
// import { updateAddressList } from '../../../store/action_calls';
import { GeneralSelectField } from '../../../components/Custom/editableField';

const AddressModal = props => {
    const [showModal, setShowModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [addressState, setaddressState] = useState({});


    const loadAddress = async (id) => {
        // console.log("getAddress")
        if (showEditModal) {
            // console.log("getAddress", showEditModal, id)
            const data = await Addresses.getAddress(id)
            data && setaddressState(data)
        }
    }
    
    useEffect(async () => {
        await loadAddress(props.address_id)
    }, [showEditModal])

    // extract addresses from google autocomplete returned object on selection
    const handlePlaceSelection = (place) => {
      let address = {
        city: null,
        state: null,
        county: null,
        country: null,
        zipcode: null,
        street_1: '',
        longitude: null,
        latitude: null
    };
      // console.log(place)
      place.address_components.forEach(element => {
         let type = element.types[0]
         if (type === "locality") {
            address["city"] = element['long_name']
         } else if (type === "administrative_area_level_1") {
            address["state"] = element['short_name']
         }
         else if (type === "administrative_area_level_2") {
            address["county"] = element['long_name']
         }
         else if (type === "country") {
            address["country"] = element['long_name']
         }
         else if (type === "postal_code") {
            address["zipcode"] = element['long_name']
         }
         else if (type === "street_address" || type==="street_number" || type==="route" ) {
            let street_1 = element['long_name'];
            address.street_1?address["street_1"] += " " + street_1 : address["street_1"] = street_1
         }
      })
      const {lat ,lng}=place.geometry.location
      address['longitude']=lng()
      address['latitude']=lat()
        setaddressState(prev=>{return {...prev,...address}})
    }



    useEffect(() => {
        Geocode.setApiKey(GOOGLE_MAP_API_KEY); //Insert your Google Maps API here
        Geocode.enableDebug();

    }, [])

    const findLatLng=()=>{
        Geocode.fromAddress(addressState.city||addressState.state).then(
            (response) => {
                const { lat, lng } = response.results[0].geometry.location;
                // console.log("you clicked there you go",lat, lng);
                setaddressState(prev=>({...prev,latitude:lat,longitude:lng}))
            },
            (error) => {
                console.error(error);
            }
        );
    }


    const handleEdit = (event) => {
        const { name, value } = event.target
        setaddressState({
            ...addressState,
            [name]: value
        })
    }


    const createAddress = async(body) =>  {
        if(showEditModal){
            const data=await Addresses.updateAddress(body.id,body)
            // console.log("update1",data)
            setaddressState("")
            setShowEditModal(false)
            props.openEdit&&props.setOpenEdit(false)
            props.newAddress&&props.newAddress(data.id,data.street_1 + ', ' + data.city + ', ' + data.state + ' ' + data.zipcode)
            return
        }
        const data=await Addresses.createAddress(body)
        // console.log("updateAddressList",{data})
        setShowModal(false)
        props.open&&props.setOpen(false)
        props.newAddress&&props.newAddress(data.id,data.street_1 + ', ' + data.city + ', ' + data.state + ' ' + data.zipcode)
        // console.log("Create", data)
    }


    const onSubmit = () => {
        // this is a defined arrow function above
        createAddress(addressState)
        setaddressState({})
        setShowModal(false)
        props.open&&props.setOpen(false)
    }


    return (
        <React.Fragment>
            <Col lg={1}>
                <OverlayTrigger overlay={<Tooltip>Edit Address</Tooltip>} style={{ float: "right" }}>
                    <Button className=" bg-transparent m-1 border-0" disabled={props.address_id===undefined } size="sm" onClick={() => {setShowEditModal(true)}} style={{ float: "right" }}>
                        <i className="feather icon-edit fa-2x text-dark mx-n3" />
                    </Button>
                </OverlayTrigger>
            </Col>
            <Col lg={1}>
                <OverlayTrigger overlay={<Tooltip>Create New Address</Tooltip>} style={{ float: "right" }}>
                    <Button className=" bg-transparent m-1 border-0" size="sm" onClick={() => setShowModal(true)} style={{ float: "right" }}>
                        <i className="feather icon-plus fa-2x text-dark mx-n3" />
                    </Button>
                </OverlayTrigger>
            </Col>

            <Modal size="lg" show={showModal} onHide={() => {setShowModal(false);props.open&&props.setOpen(false)}}>
                <Modal.Header closeButton>
                    <Modal.Title as="h5">New Address</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div style={{marginBottom:20,display:'flex',width:'100%',justifyContent:'space-between'}}>
                        <Button className="shadow-1 theme-bg border border-0"
                                onClick={findLatLng}>
                            Coordinates
                        </Button>

                        <Autocomplete
                            style={{
                                padding:10,
                                width: "80%",
                                margin:'20 15'
                            }}
                            apiKey={GOOGLE_MAP_API_KEY}
                            onPlaceSelected={handlePlaceSelection}
                            options={{
                    types: ['address'], // Restrict the search to addresses only
                    componentRestrictions: { country: 'us' } // Restrict results to the United States
                 }}
                        />

                    </div>
                    <Form>
                        {/* this input field for address selection of the react-google-autocomplete */}
                        <Form.Group as={Row}>
                            <Form.Label className={"mb-n5"} column xs={4} md={3}>Street 1</Form.Label>
                            <Col>
                                <Form.Control
                                    name="street_1"
                                    value={addressState.street_1}
                                    onChange={handleEdit}

                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Form.Label className={"mb-n5"} column xs={4} md={3}>Street 2</Form.Label>
                            <Col>
                                <Form.Control
                                    name="street_2"
                                    value={addressState.street_2}
                                    onChange={handleEdit}
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Form.Label className={"mb-n5"} column xs={4} md={3}>City</Form.Label>
                            <Col>
                                <Form.Control
                                    name="city"
                                    value={addressState.city}
                                    onChange={handleEdit}
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Form.Label className={"mb-n5"} column xs={4} md={3}>County</Form.Label>
                            <Col>
                                <Form.Control
                                    name="county"
                                    value={addressState.county}
                                    onChange={handleEdit}
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Form.Label className={"mb-n5"} column xs={4} md={3}>State</Form.Label>
                            <Col>
                                <Form.Control
                                    name="state"
                                    value={addressState.state}
                                    onChange={handleEdit}
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Form.Label className={"mb-n5"} column xs={4} md={3}>Postal Code</Form.Label>
                            <Col>
                                <Form.Control
                                    name="zipcode"
                                    value={addressState.zipcode}
                                    onChange={handleEdit}
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Form.Label className={"mb-n5"} column xs={4} md={3}>Country</Form.Label>
                            <Col>
                                <Form.Control
                                    name="country"
                                    value={addressState.country}
                                    onChange={handleEdit}
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Form.Label className={"mb-n5"} column xs={4} md={3}>Latitude</Form.Label>
                            <Col>
                                <Form.Control
                                    name="latitude"
                                    value={addressState.latitude}
                                    onChange={handleEdit}
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Form.Label className={"mb-n5"} column xs={4} md={3}>Longitude</Form.Label>
                            <Col>
                                <Form.Control
                                    name="longitude"
                                    value={addressState.longitude}
                                    onChange={handleEdit}
                                />
                            </Col>
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Row>
                        <Col className="float-right">
                            <div className="float-right" style={{ display: "flex" }}>
                                <OverlayTrigger overlay={<Tooltip>Discard Changes</Tooltip>} style={{ float: "right" }}>
                                    <Button className="shadow-1 theme-bg border border-0"  onClick={() => {setShowModal(false);props.open&&props.setOpen(false)}}>
                                        Cancel
                                    </Button>
                                </OverlayTrigger>
                                <OverlayTrigger overlay={<Tooltip>Save Changes</Tooltip>} style={{ float: "right" }}>
                                    <Button className="shadow-1 theme-bg border border-0" type="submit" onClick={onSubmit} >
                                        Save
                                    </Button>
                                </OverlayTrigger>
                            </div>
                        </Col>
                    </Row>
                </Modal.Footer>
            </Modal>

            <Modal size="lg" show={showEditModal} onHide={() => {setShowEditModal(false);props.openEdit&&props.setOpenEdit(false)}}>
                <Modal.Header closeButton>
                    <Modal.Title as="h5">Edit Address</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {/* <div style={{marginLeft:80,marginBottom:20}}>*/}
                    {/*    {props.addressList &&*/}

                    {/*        <>*/}
                    {/*        <GeneralSelectField field={addressState?.id}*/}
                    {/*        value="id"*/}
                    {/*        show="display_address"*/}
                    {/*        label="id"*/}
                    {/*        caplabel="Address"*/}
                    {/*        selectOptions={Object.values(props.addressList)}*/}
                    {/*        fieldwritten={addressState?.id || props.selected}*/}
                    {/*        state={addressState}    */}
                    {/*        setState={setaddressState}*/}
                    {/*        defaultSwitch={false}*/}
                    {/*        setAll={true}*/}
                    {/*        />*/}
                    {/*        </>*/}
                    {/*    }*/}

                    {/*</div>           */}
                    <Form>
                        {/* this input field for address selection of the react-google-autocomplete */}
                        <Form.Group as={Row}>
                            <Form.Label className={"mb-n5"} column xs={4} md={3}>Street 1</Form.Label>
                            <Col>
                                <Form.Control
                                    name="street_1"
                                    value={addressState.street_1}
                                    onChange={handleEdit}

                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Form.Label className={"mb-n5"} column xs={4} md={3}>Street 2</Form.Label>
                            <Col>
                                <Form.Control
                                    name="street_2"
                                    value={addressState.street_2}
                                    onChange={handleEdit}
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Form.Label className={"mb-n5"} column xs={4} md={3}>City</Form.Label>
                            <Col>
                                <Form.Control
                                    name="city"
                                    value={addressState.city}
                                    onChange={handleEdit}
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Form.Label className={"mb-n5"} column xs={4} md={3}>County</Form.Label>
                            <Col>
                                <Form.Control
                                    name="county"
                                    value={addressState.county}
                                    onChange={handleEdit}
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Form.Label className={"mb-n5"} column xs={4} md={3}>State</Form.Label>
                            <Col>
                                <Form.Control
                                    name="state"
                                    value={addressState.state}
                                    onChange={handleEdit}
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Form.Label className={"mb-n5"} column xs={4} md={3}>Postal Code</Form.Label>
                            <Col>
                                <Form.Control
                                    name="zipcode"
                                    value={addressState.zipcode}
                                    onChange={handleEdit}
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Form.Label className={"mb-n5"} column xs={4} md={3}>Country</Form.Label>
                            <Col>
                                <Form.Control
                                    name="country"
                                    value={addressState.country}
                                    onChange={handleEdit}
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Form.Label className={"mb-n5"} column xs={4} md={3}>Latitude</Form.Label>
                            <Col>
                                <Form.Control
                                    name="latitude"
                                    value={addressState.latitude}
                                    onChange={handleEdit}
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Form.Label className={"mb-n5"} column xs={4} md={3}>Longitude</Form.Label>
                            <Col>
                                <Form.Control
                                    name="longitude"
                                    value={addressState.longitude}
                                    onChange={handleEdit}
                                />
                            </Col>
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Row>
                        <Col className="float-right">
                            <div className="float-right" style={{ display: "flex" }}>
                                <OverlayTrigger overlay={<Tooltip>Discard Changes</Tooltip>} style={{ float: "right" }}>
                                    <Button className="shadow-1 theme-bg border border-0"  onClick={() => {setShowEditModal(false);setaddressState("");props.openEdit&&props?.setOpenEdit(false)}}>
                                        Cancel
                                    </Button>
                                </OverlayTrigger>
                                <OverlayTrigger overlay={<Tooltip>Save Changes</Tooltip>} style={{ float: "right" }}>
                                    <Button className="shadow-1 theme-bg border border-0" type="submit" onClick={onSubmit} >
                                        Save
                                    </Button>
                                </OverlayTrigger>
                            </div>
                        </Col>
                    </Row>
                </Modal.Footer>
            </Modal>
        </React.Fragment>
    );
};


export default (AddressModal)