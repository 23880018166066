import http from "./httpService";
import {floatFormatterForFamilyLead, floatFormatterForInvestor} from '../components/Custom/floatFormatter.js'
import {fieldTypeContact} from "../config/contact/fieldTypeContact.js"
// Import Endpoints
import { investorUrlv1,investorUrlShort,investorDropdown} from '../config/Endpoints';


const apiEndpoint=`${investorUrlv1}`

const getInvestors=async(query="")=>{
   try {
       const {data}=await http.get(apiEndpoint+query)
       const parsedList = floatFormatterForInvestor(data.results, fieldTypeContact)
       return {...data,results:parsedList}
   } catch (error) {
       console.log(error)
       return
   }

}

const getInvestorsv1=async(query="")=>{
    try {
        const {data}=await http.get(apiEndpoint+query)
        const parsedList = floatFormatterForInvestor(data.results, fieldTypeContact)
        return {...data,results:parsedList}
    } catch (error) {
        console.log(error)
        return
    }
 
 }


const getInvestordd=async(query="")=>{
try {
    const {data}=await http.get(investorDropdown+query)
    // const parsedList = floatFormatterForInvestor(data, fieldTypeContact)
    // return {...data,results:parsedList}
    return data
} catch (error) {
    console.log(error)
    return
}

}

 



const getInvestorsShort=async()=>{
    try {
        const {data}=await http.get(investorUrlShort)
        return [{full_name:'Remove Investor',id:null},...data]
    } catch (error) {
        console.log(error)
        return
    }
 }




const getInvestor=async(id)=>{
    if(!id) return {}
    try {
        const {data}=await http.get(`${apiEndpoint}${id}`)
        const parsedList = floatFormatterForInvestor(data, fieldTypeContact)
        return parsedList
    } catch (error) {
        console.log(error)
        return {}
    }
 
 }


  const updateInvestor = async(id,investor)=>{
    try {
       const {data}= await http.patch(`${apiEndpoint}${id}/`,investor)
       const parsedList = floatFormatterForFamilyLead(data, fieldTypeContact)
       return parsedList
    } catch (error) {
        console.log(error)
        return
    }
}


const getOptions = async() => {
    try {
        const {data}=await  http.options(`${apiEndpoint}`)
        console.log(data,'optiond')
        return data.actions.POST.contact.children
      } catch (error) {
          console.log(error)
      }
}


export default {
    getInvestors,
    updateInvestor,
    getInvestor,
    getOptions,
    getInvestorsShort,
    getInvestorsv1,
    getInvestordd
}