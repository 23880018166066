import React from 'react';
import { ListGroup } from 'react-bootstrap';
import NavCollapse from '../NavCollapse';
import NavItem from '../NavItem';
import { useSelector } from 'react-redux';

const NavGroup = ({ layout, group }) => {
   const loggedInUser = useSelector(state => state.account.user?.user);

   const userIsFamily = loggedInUser && loggedInUser.is_family; // logged in user has is_family key
   const userIsEmployee = loggedInUser && loggedInUser.is_employee; // logged in user has is_family key
   const userIsInvestor = loggedInUser && loggedInUser.is_investor

   let navItems = '';

   if (group.children) {
      const groups = group.children;
      // let roleItems = [];
      const roleItems = Object.keys(groups).map(item => {
         return groups[item];
      });

      navItems = roleItems.map(item => {
         // item = groups[item];
         switch (item.type) {
            case 'collapse':
               return <NavCollapse key={item.id} collapse={item} type="main" />;
            case 'item':
               return <NavItem layout={layout} key={item.id} item={item} />;
            default:
               return false;
         }
      });
   }

   return (
      <React.Fragment>
         <ListGroup.Item
            as="li"
            bsPrefix=" "
            key={group.id}
            className="nav-item pcoded-menu-caption"
         >
            <label>{group.title}</label>
         </ListGroup.Item>
         {navItems}
      </React.Fragment>
   );
};

export default NavGroup;
