import http from './httpService';
import {toast} from "react-toastify";
import { floatFormatterForInvestor } from '../components/Custom/floatFormatter.js';
import { fieldTypeContact } from '../config/contact/fieldTypeContact.js';
// Import Endpoints
import {
   loansPaymentIntent,
   loansDetailUrl,
   loansShortUrl,
   loansUrl,
   eviction,
   stripePayment,
   authnetUrl,
   loansUrlv1,
   addressUrlV1,
} from '../config/Endpoints';
import { floatFormatterForFamilyLead } from '../components/Custom/floatFormatter.js';
import { fetchNextDataApi } from '../components/FetchNext';

const apiEndpoint = `${loansUrl}`;
const loanApiV1EndPoint = `${loansUrlv1}`;
// const apiEndpointD=`${propertiesUrlDetail}`

const getLoans = async (query = '') => {
   try {
      const { data } = await http.get(apiEndpoint + query);
      //    const parsedList = floatFormatterForInvestor(data.results, fieldTypeContact)
      //    console.log("xyzx",parsedList)
      const data2 = await fetchNextDataApi(data.next);
      // console.log('pista', data.results, data2);
      return data;
   } catch (error) {
      console.log(error);
      return;
   }
};

const getShorts = async () => {

   try {
      const { data } = await http.get(loansShortUrl);
      //    const parsedList = floatFormatterForInvestor(data.results, fieldTypeContact)
      //    console.log("xyzx",parsedList)
      const data2 = await fetchNextDataApi(data.next);
      // console.log('pista', data.results, data2);
      return data;
   } catch (error) {
      console.log(error);
      return;
   }
};

const getEviction = async () => {
   try {
      const { data } = await http.get(eviction);
      return data;
   } catch (error) {
      console.log(error);
      return;
   }
};


const createLoan = async loan => {
   try {
      const { data } = await http.post(`${loansUrlv1}`, loan);
      toast.success('Loan created successfully');
      return data;
   } catch (error) {
      toast.error('Loan creation failed '+ error.message);
      console.log(error);
      return;
   }
};

// const createTrust=async(scrape)=>{
//     try {
//         const {data}= await http.post(`${createTrustAcq}`,scrape)
//          return data
//      } catch (error) {
//          console.log(error)
//          return
//      }
// }

// const runScrape=async(id)=>{
//     try {
//         const {data}= await http.post(`${rerunScrape}`,id)
//          return data
//      } catch (error) {
//          console.log(error)
//          return
//      }
// }

// const getScrapes=async()=>{
//     try {
//         const {data}= await http.get(`${acqCreateScrape}`)
//          return data
//      } catch (error) {
//          console.log(error)
//          return
//      }
// }

// // const getAmbassadorss=async(query="")=>{
// //     try {
// //         const {data}=await http.get(apiEndpoint+query)
// //         // const parsedList = floatFormatterForInvestor(data.results, fieldTypeContact)
// //         // console.log("xyzx",parsedList)
// //         return data

// //     } catch (error) {
// //         console.log(error)
// //         return
// //     }
// //  }

// const getProperty=async(id)=>{
//     if(!id) return {}
//     try {
//         const {data}=await http.get(`${apiEndpointD}${id}`)
//         // const parsedList = floatFormatterForInvestor(data, fieldTypeContact)
//         return data
//     } catch (error) {
//         console.log(error)
//         return {}
//     }
// }

const getLoan = async id => {
   if (!id) return {};
   try {
      const { data } = await http.get(`${loansUrlv1}${id}`);
      // const parsedList = floatFormatterForInvestor(data, fieldTypeContact)
      return data;
   } catch (error) {
      console.log(error);
      return {};
   }
};

const fetchPaymentIntent = async id => {
   try {
      const { data } = await http.post(`${loansPaymentIntent}`, id);
      return data;
   } catch (error) {
      console.log(error);
      return;
   }
   // const response = await fetch(`${loansPaymentIntent}`, {
   //   method: 'POST',
   //   headers: {
   //     'Content-Type': 'application/json',
   //   },
   //   body: JSON.stringify({
   //     // This is an example request body, the parameters you pass are up to you
   //     customer: '<CUSTOMER_ID>',
   //   }),
   // });
   // const {clientSecret} = await response.json();

   // return clientSecret;
};

const updateLoan = async (id, property) => {
   try {
      const pro = property;
      delete pro.name;
      const { data } = await http.put(`${apiEndpoint}${id}/`, pro);
      toast.success('Loan updated successfully');
      return data;
   } catch (error) {
      toast.error('Loan update failed '+ error.response.request.responseText);
      console.log(error);
      return;
   }
};

const updateLoanPatch = async (id, property) => {
   try {
      const pro = property;
      delete pro.name;
      const { data } = await http.put(`${apiEndpoint}${id}/`, pro);
      toast.success('Loan updated successfully');
      return data;
   } catch (error) {
      toast.error('Loan update failed '+ error.response.request.responseText);
      console.log(error);
      console.log('Error updating loan >>>', error?.response?.data);
      return;
   }
};

const changeAutoPay = async (id, property) => {
   try {
      const pro = property;
      delete pro.name;
      const { data } = await http.patch(`${loanApiV1EndPoint}${id}/`, pro);
      return data;
   } catch (error) {
      toast.error('Change Auto Pay failed '+ error.response.request.responseText);
      console.log(error);
      console.log('Error updating loan >>>', error?.response?.data);
      return;
   }
};

// const updateScrape=async(id,property)=>{
//     try {
//        const {data}= await http.put(`${acqCreateScrape}${id}/`,property)
//         return data
//     } catch (error) {
//         console.log(error)
//         return
//     }
// }

// const getDataPDF=async(arr)=>{
//     try {
//        const {data}= await http.post(`${rtpPDF}`,{ids:arr})
//         return data
//     } catch (error) {
//         console.log(error)
//         return
//     }
// }

// const createProperty=async(property)=>{
//     try {
//        const {data}= await http.post(`${apiEndpoint}`,property)
//         return data
//     } catch (error) {
//         console.log("chup",error)
//         return error
//     }
// }

const deletePaymentMethod = async id => {
   try {
      const { data } = await http.delete(`${stripePayment}${id}/`);
      toast.success('Bank deleted successfully');
      return data;
   } catch (error) {
      toast.error('Bank deletion failed '+ error.response.request.responseText);
      console.log(error);
      return;
   }
};

const deleteCard = async id => {
   try {
      const { data } = await http.delete(`${authnetUrl}${id}/`);
      toast.success('Card deleted successfully');
      return data;
   } catch (error) {
      toast.error('Card deletion failed '+ error.response.request.responseText);
      console.log(error);
      return;
   }
};

const getOptions = async () => {
   try {
      const { data } = await http.options(`${apiEndpoint}`);
      return data;
   } catch (error) {
      console.log(error);
      return;
   }
};

// const getOptionsStage=async()=>{
//     try {
//        const {data}= await http.options(`${acqCreateScrape}`)
//         return data
//     } catch (error) {
//         console.log(error)
//         return
//     }
// }

const getUserLoans = async contactId => {
   try {
      const { data } = await http.get(`${loansUrlv1}`);
      return data;
   } catch (error) {
      console.log(error);
      return {};
   }
};

export default {
   getLoans,
   getLoan,
   getShorts,
   updateLoan,
   fetchPaymentIntent,
   updateLoanPatch,
   getEviction,
   getOptions,
   createLoan,
   deletePaymentMethod,
   deleteCard,
   changeAutoPay,
   getUserLoans,
};
