import http from './httpService';

// Import Endpoints
import {
   applicationEndPoint,
   applicationdocEndPoint,
   subContactApi,
   addressUrlV1,
   loginUser,
} from '../config/Endpoints';
import { Notify } from '../components/Notifications/Notify';

const getOptions = async () => {
   try {
      const { data } = await http.options(`${applicationEndPoint}`);
      // console.log('application options >>>>', data);
      return data;
   } catch (error) {
      console.log('error getting options >>>>', error);
      return;
   }
};

const createApp = async formData => {
   try {
      const response = await http.post(`${applicationEndPoint}`, formData);
      Notify('success', 'AUTOSAVE: Application details saved successfully');

      return response;
   } catch (error) {
      console.log('error creating application >>>>', error.response);

      return error.response;
   }
};


const uploadDoc = async formData => {
   try {
      const { data } = await http.post(`${applicationdocEndPoint}`, formData, {
         headers: {
            'Content-Type':
               'multipart/form-data; boundary=----WebKitFormBoundary7MA4YWxkTrZu0gW',
         },
      });
      Notify('success', 'AUTOSAVE: A document was uploaded successfully');
      return data;
   } catch (error) {
      console.log(error);
      return;
   }
};

const getApplication = async id => {
   if (!id) return {};
   try {
      const { data } = await http.get(`${applicationEndPoint}${id}`);
      return data;
   } catch (error) {
      console.log(error);
      return { error: 'There was an error' };
   }
};



const getFiles = async id => {
   if (!id) return {};
   try {
      const { data } = await http.get(
         `${applicationdocEndPoint}?application_id=${id}`
      );
      return data;
   } catch (error) {
      console.log(error);
      return {};
   }
};


const updateApplication = async (body, id) => {
   // console.log(body, id);
   if (!id) return {};
   // console.log('hoiiii');
   try {
      const { data } = await http.put(`${applicationEndPoint}${id}/`, body);
      // console.log(data, '');
      return data;
   } catch (error) {
      console.log(error);
      return {};
   }
};

const createCoApplicant = async formData => {
   try {
      const { data } = await http.post(`${subContactApi}`, formData);
      Notify('success', 'AUTOSAVE: Your co-applicant info was saved');

      return data;
   } catch (error) {
      // console.log(error.response);
      if (error.response?.data) {
         Notify('error', `${error.response.data.message.phone}`);
      }
      return;
   }
};

const createEmergencyContact = async formData => {
   try {
      const { data } = await http.post(`${subContactApi}`, formData);
      Notify('success', 'AUTOSAVE: Your emergency contact info was saved');

      return data;
   } catch (error) {
      console.log(error.response);
      if (error.response?.data) {
         Notify('error', `${error.response.data.message.phone}`);
      }
      return false;
   }
};

const getAddressesData = async filter => {
   try {
      const { data } = !filter
         ? await http.get(`${addressUrlV1}`)
         : await http.get(`${addressUrlV1}?type=${filter}`);
      return data;
   } catch (error) {
      console.log(error);
      return {};
   }
};

const createContactAddress = async formData => {
   try {
      const { data } = await http.post(`${addressUrlV1}`, formData);
      Notify('success', 'AUTOSAVE: Your address contact info was saved');

      return data;
   } catch (error) {
      console.log(error.response);
      return;
   }
};


export default {
   uploadDoc,
   createApp,
   getOptions,
   getApplication,
   updateApplication,
   getAddressesData,
   getFiles,
   createCoApplicant,
   createEmergencyContact,
   createContactAddress,
};
