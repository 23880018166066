import React, {useEffect, useState} from 'react';

import {Row, Form, Col, Button, Modal} from 'react-bootstrap';
import {
    CardElement,
    Elements,
    useStripe,
    useElements,
} from '@stripe/react-stripe-js';
import {loadStripe} from "@stripe/stripe-js";
import LoadingHand from "../../../components/Loader/LoadingHand";

// import { CONFIG } from '../../../config/constant';
import {stripePaymentAccounts} from '../../../config/Endpoints';
import {postData} from "../../../services/endpointService";
import {Notify} from "../../Notifications/Notify";

// console.log(CONFIG.stripeKey)
// const stripePromise = loadStripe(CONFIG.stripeKey)


const CreateBank = ({intent, contactData, context}) => {
    const [intentLoaded, setIntentLoaded] = useState(false  )
    const [isLoaded, setIsLoaded] = useState(false)
    const [setupIntentState, setSetupIntentState] = useState({})
    const [requiresConfirmation, setRequiresConfirmation] = useState(false)
    const [mandateAccepted, setMandateAccepted] = useState(false)

    const stripe = useStripe();
    const {reloadAccounts} = context

    console.log({intent})

    function handleBankAccount() {
        if (!stripe) {
            return
        }
        stripe.collectBankAccountForSetup({
            clientSecret: intent?.client_secret,
            params: {
                payment_method_type: 'us_bank_account',
                payment_method_data: {
                    billing_details: {
                        name: contactData.name,
                        email: contactData.primary_email
                    }
                }
            },
            expand: ['payment_method']
        })
            .then(({setupIntent, error}) => {
                // setIsLoaded(true)
                // console.log("set up intent in create bank")
                // console.log(setupIntent)
                setIsLoaded(true)
                if (error) {
                    Notify('danger', error)
                } else {
                    setSetupIntentState(setupIntent)
                }
            })
    }

    function handleSetupIntent() {
        if (setupIntentState.status === 'requires_payment_method') {
            // handle getting payment method
            // console.log('requires_payment_method')
        } else if (setupIntentState.status === 'requires_confirmation') {
            // console.log('confirm payment method')
            setRequiresConfirmation(true)
        } else if (setupIntentState.status === 'succeeded') {
            setMandateAccepted(true)
            Notify('success', "Successfully added Bank Account")
            reloadAccounts()
        } else if (setupIntentState.status === 'verify_with_microdeposits') {
            // handle microdeposits
        }
    }

    function handleConfirmation() {
        if (!stripe) {
            Notify('danger', "Error with account confirmation")
            return
        }
        stripe.confirmUsBankAccountSetup(intent.client_secret)
            .then(({setupIntent, error}) => {
                // console.log(setupIntent)
                if (error) {
                    Notify('danger', error)
                } else {
                    const data = {
                        stripe_id: setupIntent.payment_method,
                        customer_instance: intent.customer_id.id
                    }
                    console.log(data)
                    postData(stripePaymentAccounts, data)
                       .then((res) => {
                           if (res.status === 201) {
                               setSetupIntentState(setupIntent)
                           } else {
                               Notify('danger', "Error creating back end record.")
                           }
                       })
                }
        })
    }

    useEffect(() => {
        if (mandateAccepted) {
            handleConfirmation()
        }
    }, [mandateAccepted]);

    useEffect(() => {
        if (setupIntentState && setupIntentState.status) {
            handleSetupIntent()
        }
    }, [setupIntentState]);

    useEffect(() => {
        if (intentLoaded && stripe) {
            // console.log("starting bank account")
            handleBankAccount()
        }
    }, [intentLoaded, stripe]);

    useEffect(() => {
        if (intent && Object.keys(intent).length > 0) {
            // console.log('intent loaded')
            setIntentLoaded(true)
        }
    }, [intent]);

    const Mandate = ({ mandateAccepted, setMandateAccepted}) => {
        const company = "EasyHomes/Equity & Help"

        return (
            <>
                <Modal
                    size='xxl'
                    show={requiresConfirmation && !mandateAccepted}
                    onHide={() => setRequiresConfirmation(false)}
                    centered
                >
                    <Modal.Header>
                        <Modal.Title as='h5'>Payment Method Authorization</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Col md={12}>
                            <Row style={{paddingTop: 15}}>
                                <p className="text-center">
                                    By clicking "Accept", you authorize {company} to debit the bank account specified above for any amount owed for charges arising from your use of {company}'s services and/or purchase of products from {company}, pursuant to {company}'s website and terms, until this authorization is revoked. You may amend or cancel this authorization at any time by providing notice to {company} with 30 (thirty) days notice.
                                </p>
                                <p className="text-center">
                                    If you use {company}'s services or purchase additional products periodically pursuant to {company}’s terms, you authorize {company} to debit your bank account periodically. Payments that fall outside of the regular debits authorized above will only be debited after your authorization is obtained.
                                </p>
                            </Row>
                            <Row style={{paddingBottom: 15}}>
                                <Col />
                                <Col className={'flex'}>
                                    <Button
                                        className="btn-sm button text-white shadow-1 btn-danger border border-0 "
                                        onClick={() => {
                                            setRequiresConfirmation(false)
                                        }}
                                    >
                                        Decline
                                    </Button>
                                    <Button
                                        className="btn-sm shadow-1 text-white btn-primary border border-0 "
                                        onClick={() => {
                                            setRequiresConfirmation(false)
                                            setMandateAccepted(true)
                                        }}
                                    >
                                        Accept
                                    </Button>
                                </Col>
                                <Col />
                            </Row>
                        </Col>
                    </Modal.Body>
                </Modal>
            </>
        )
    }

    return (
        <Row stripe={stripe}>
            {
                requiresConfirmation &&
                <Mandate mandateAccepted={mandateAccepted} setMandateAccepted={setMandateAccepted} />
            }
            {/*<Mandate mandateAccepted={mandateAccepted} setMandateAccepted={setMandateAccepted} />*/}
        </Row>
    )
}

export default CreateBank;