import { CustomLinkCall } from "../services"

export const fetchNextDataApi = async (nextUrl, setRowData) => {
    const arr = []
    while (nextUrl) {
        const newData = await CustomLinkCall.getResposeWithCustomeLink(nextUrl)
        if (!newData) continue
        nextUrl = newData.next
        // console.log("Pagination Done",newData.results)
        if (setRowData)
            setRowData((state) => [...state, ...newData.results])
        else {
            arr.push(...newData.results)
        }
    }
    if (!setRowData) {
        return arr
    }
}