import http from "./httpService";
import {floatFormatterForInvestor} from '../components/Custom/floatFormatter.js'
import {fieldTypeContact} from "../config/contact/fieldTypeContact.js"
// Import Endpoints
import { ambassadorUrl,ambassadorLeadStageUrl,massAmbassadorDel } from '../config/Endpoints';
import {floatFormatterForFamilyLead} from '../components/Custom/floatFormatter.js'
import httpService from "./httpService";

const source=httpService.getSource()
const apiEndpoint=`${ambassadorUrl}`

const getAmbassadors=async(query="")=>{
   try {
       const {data}=await http.get(apiEndpoint+query)
       const parsedList = floatFormatterForInvestor(data.results, fieldTypeContact)
       // console.log("xyzx",parsedList)
       return {...data,results:parsedList}
        
   } catch (error) {
       console.log(error)
       return
   }
}

const getStages=async()=>{
    try {
        const {data}=await http.get(ambassadorLeadStageUrl,{ cancelToken: source.token })
        return data
    } catch (error) {
        console.log(error)
        return []
    }
 
 }

const getAmbassadorss=async(query="")=>{
    try {
        const {data}=await http.get(apiEndpoint+query)
        // const parsedList = floatFormatterForInvestor(data.results, fieldTypeContact)
        // console.log("xyzx",parsedList)
        return data
         
    } catch (error) {
        console.log(error)
        return
    }
 }

 const deleteAmbassdors=async(property)=>{
    try {
       const {data}= await http.post(`${massAmbassadorDel}`,property)
        return data
    } catch (error) {
        console.log(error)
        return
    }
}


const getAmbassador=async(id)=>{
    if(!id) return {}
    try {
        const {data}=await http.get(`${apiEndpoint}${id}`)
        // const parsedList = floatFormatterForInvestor(data, fieldTypeContact)
        return data
    } catch (error) {
        console.log(error)
        return {}
    }
}



const getOptions = async() => {
    try {
        const {data}=await  http.options(`${apiEndpoint}`)
        // console.log(data,'optiond')
        return data.actions.POST.contact.children
      } catch (error) {
          console.log(error)
      }
}

const updateAmbassador=async(id,ambassador)=>{
    try {
       const {data}= await http.patch(`${apiEndpoint}${id}/`,ambassador)
        return data
    } catch (error) {
        console.log(error)
        return
    }
}

const AmbassadorDrop=async()=>{
    try {
       const {data}= await http.get(`${apiEndpoint}`)
                return data.results.map(obj => ({ name: obj.contact.full_name, id: obj.id }));
    } catch (error) {
        console.log(error)
        return
    }
}





export default {
    getAmbassadors,
    getAmbassador,
    getOptions,
    getAmbassadorss,
    getStages,
    updateAmbassador,
    AmbassadorDrop,
    deleteAmbassdors
}