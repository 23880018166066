import { toast } from 'react-toastify';


const options = {
   //todo maybe make this top right? - austin
   position: toast.POSITION.BOTTOM_RIGHT,
}

export const Notify = (type, message) => {
   if (type === 'success') {
      toast.success(message, options);
   } else if (type === "info") {
      toast.info(message, options)
   } else if (type === "warning") {
      toast.warning(message, options)
   } else {
      toast.error(message, options);
   }
};
