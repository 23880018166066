import React, { useEffect } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import { useSelector, connect } from 'react-redux';
import {
   getContacts,
   getTrusts,
   getAddresses,
   getInvestors,
   getFamilyLeads,
   getInvestorLeads,
   getFamilies,
   getSituations,
   getAmbassadors,
   getInvestorPortalData
} from '../../store/action_calls';

import Login from '../../views/auth/signin/SignIn';
import httpService from '../../services/httpService';
import menuItems from '../../menu-items';


const AuthGuard = props => {

   const account = useSelector(state => state.account);
   const { isLoggedIn, user } = account;
   const history = useHistory();

   useEffect(() => {
      httpService.setTokenToAxios(user?.access_token);
   }, [user?.access_token]);

   useEffect(() => {
      if (!isLoggedIn) return;
   }, []);

   const currentLocation = history.location.pathname;

   // if (!isLoggedIn) return <Redirect to='/auth/signin' />;
   if (!isLoggedIn) return <Redirect to="/auth/signin-2" />;

   // Check that if a user is a family user, they can only visit family
   // urls only.
   // let familyOnlyPaths = [];
   const famItems = menuItems.items[0].children.find((item) => {
      return item.id === 'fam-web';
   })
   // console.log(famItems)
   const familyOnlyPaths = famItems.children.map(item => item.url)
   // menuItems.items[0].children.map(item => {
   //    if (item.family) {
   //       familyOnlyPaths.push(item.url);
   //    }
   // });

   // console.log(familyOnlyPaths)

   const investorOnlyPaths = menuItems.investor[0].children.map((item) => {
      return item.url
   }).concat([
      '/investor-portal/requests/',
   ])

   // console.log(investorOnlyPaths.includes(currentLocation))

   const userIsFamily = user?.user && user.user.is_family; // logged in user has is_family key
   const userIsInvestor = user?.user && user.user.is_investor;
   const userIsEmployee = user?.user && user.user.is_employee;
   const userIsApplicant = user?.user && user.user.is_applicant;


   //todo when logging in as an applicant it doens't return is applicant for fmaily stuff. this should be fixed then is appliant can be what's used to reroute user
   // const userIsEmployee = user?.user && 'is_employee' in user?.user; // logged in user has is_family key

   if (userIsEmployee)  {
      // console.log("employee")
      return props.children
   }

   if (userIsApplicant) {
      if (history.location.pathname.includes("website") && (!userIsFamily || !userIsInvestor)) {
         return <Redirect to="/website/application" />;
      }
   }

   if (userIsInvestor && currentLocation.includes('/investor-portal')) {
      return props.children
   }
   if (userIsFamily && currentLocation.includes('/famportal')) {
      return props.children
   }

   if (investorOnlyPaths.includes(currentLocation)  && !userIsInvestor) {
      if (userIsFamily) {
         return <Redirect to="/famportal" />
      }
   }

   if (familyOnlyPaths.includes(history.location.pathname) && !userIsFamily) {
      if (userIsInvestor) {
         return <Redirect to="investor-portal/monthly" />
      }
   }

   if (
      !investorOnlyPaths.includes(history.location.pathname) &&
      !familyOnlyPaths.includes(history.location.pathname) &&
      !history.location.pathname.includes('website') &&
      !userIsEmployee
   ) {
      return <Redirect to='/website' />
   }

   return props.children
};

export default connect(null, {
   getFamilies,
   getContacts,
   getTrusts,
   getAddresses,
   getInvestors,
   getFamilyLeads,
   getInvestorLeads,
   getSituations,
   getAmbassadors,
})(AuthGuard);
