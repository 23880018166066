import React, { useEffect, useRef, useState } from "react";
import { Col, Form, Row, Tooltip, OverlayTrigger } from "react-bootstrap";
import AddressModal from "../../pages/address/modals/AddressModal";
import fieldDateFormatter from "./fieldDateFormatter";
import Datetime from "react-datetime";
import moment from "moment";
import { useHistory } from 'react-router-dom';
import NumberFormat from "react-number-format";
import ReactTags from "react-tag-autocomplete";
// Import API Url
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/bootstrap.css'
import './editableField.css'
import Select from 'react-select'
import mapOptions from "./mapOptions";
import { FaExternalLinkAlt } from "react-icons/fa";
import { Addresses, Loans } from "../../services";
import { Notify } from "../Notifications/Notify";
import CreateCard from "../Payments/AuthNet/CreateCard";
import StripeBase from "../Payments/Stripe/StripeBase";

import { getAddressDropdown } from '../../services/apiCalls/address/Address';

//this is the basic editable text field it takes the props
//
// field={contactState.signer_name}
// name={"signer_name"}
// options={contactOptionsState.signer_name}
// state={contactState}
// setState={setContactState}
// defaultSwitch={defaultSwitch}

export const EditableField = (props) => {
    const valid = props.options === undefined

    const handleEdit = (event) => {
        // console.log("Event", event)
        const { name, value } = event.target
        props.setState({
            ...props.state,
            [name]: value
        })
    }

    return (
        <Form.Group as={Row}>
            {valid ? " " :
                <Form.Label column sm={4}>{props.options.label}</Form.Label>
            }
            <Col sm={8}>
                <Form.Control
                    readOnly={props.defaultSwitch || props.disableEdit}
                    name={props.name}
                    plaintext={props.defaultSwitch}
                    value={props.field || ''}
                    onChange={handleEdit}
                    style={props.color ? { backgroundColor: "white" } : {}}
                />
            </Col>
        </Form.Group>
    )
}

export const GeneralEditableField = (props) => {
    let valid = props.options === undefined
    // console.log("wkwk", props.disabled.disabled)
    const handleEdit = (event) => {
        // console.log("Event", event)
        const { name, value } = event.target
        props.setState({
            ...props.state,
            [name]: value
        })
    }

    return (

        <Form.Group style={props.islink && !props.field ? { minHeight: '30px' } : null} as={Row}>
            {valid ? " " :

                <Form.Label column sm={4}>{props.options.label}</Form.Label>
            }

            <Col sm={8}>
                {props.islink && props.defaultSwitch ?
                    <a href={props.field}>{props.field}</a>

                    :
                    <Form.Control
                        readOnly={props.defaultSwitch || props.disabled.disabled}
                        name={props.name}
                        plaintext={props.defaultSwitch}
                        value={props.field ? props.field : ''}
                        onChange={handleEdit}
                    />
                }


            </Col>
        </Form.Group>
    )
}

// This is a date field that is not editable, so even when the edit mode is enabled the field will not edit.
//
// field={contactState.signer_name}
// name={"signer_name"}
// options={contactOptionsState.signer_name}
// state={contactState}
// setState={setContactState}
// defaultSwitch={defaultSwitch}

export const DateFieldNon = (props) => {
    let valid = props.options === undefined

    return (
        <Form.Group as={Row}>
            {valid ? " " :
                <Form.Label column sm={4}>{props.options.label}</Form.Label>
            }
            <Col sm={8}>
                <Form.Control
                    readOnly={true}
                    plaintext={true}
                    dateFormat="MM-DD-YYYY"
                    name={props.name}
                    value={fieldDateFormatter(props.field)}
                />
            </Col>
        </Form.Group>

    )
}


// This is a date field that is editable, same as the above but it turns into an editable field when the editing is enabled.
// it takes the date in MM/DD/YYYY. but displays it in long local format. "LL"
//
// field={contactState.signer_name}
// name={"signer_name"}
// options={contactOptionsState.signer_name}
// state={contactState}
// setState={setContactState}
// defaultSwitch={defaultSwitch}
export const DateField = (props) => {
    let valid = props.options === undefined
    const handleDates = (event, name) => {
        props.obj ?
            props.setState(
                {
                    ...props.state,
                    [name]: moment(event._d).format()
                })
            :
            props.setState({
                ...props.state,
                [name]: moment(event._d).format("YYYY-MM-DD")
            })
    };
    return (
        <Form.Group as={Row}>
            {valid ? " " :
                <Form.Label column sm={4}>{props.options}</Form.Label>
            }

            <Col sm={8}>
                {
                    props.defaultSwitch ?
                        <Form.Control
                            readOnly={true}
                            plaintext={true}
                            dateFormat="MM-DD-YYYY"
                            name={props.name}
                            value={props.field ? fieldDateFormatter(props.field) : "No Date"}
                        />

                        :
                        <Datetime
                            dateFormat="MM-DD-YYYY"
                            closeOnSelect={true}
                            timeFormat={false}
                            name="contact_create_date"
                            value={moment(props.field ? props.field : '').format("LL")}
                            onChange={event => handleDates(event, props.name)} />
                }
            </Col>
        </Form.Group>

    )
}

//this is an editable field except for it displays everything and currency both on input and output

export const CurencyField = (props) => {

    const valid = props.options === undefined;

    // console.log(props.name, props.field)

    const handleValueChange = (event, name) => {
        const value = event.value
        props.setState({
            ...props.state,
            [name]: value
        })
    }

    return (
        <Form.Group as={Row}>
            {valid ? " " :
                <Form.Label column sm={4}>{props.options.label}</Form.Label>
            }
            <Col sm={8}>
                {
                    props.defaultSwitch ?
                        <NumberFormat
                            value={props.field ? props.field : 0}
                            readOnly={true}
                            className="form-control-plaintext"
                            thousandSeparator={true}
                            prefix={'$'}
                            decimalScale={2}
                            name="insured_amount"
                        />
                        :
                        <NumberFormat
                            value={props.field ? props.field : 0}
                            readOnly={props.disabled?.disabled}
                            className="form-control"
                            thousandSeparator={true}
                            prefix={'$'}
                            // Name needs to be the field that is being updated
                            onValueChange={(defaultValue, name) => handleValueChange(defaultValue, name = props.name)}
                            decimalScale={2}
                            fixedDecimalScale={true}
                        />
                }
            </Col>
        </Form.Group>
    )
}


export const SelectField = (props) => {
    let valid = props.label === undefined

    // console.log("props.selectOptions", props.selectOptions, props.label)

    const handleEdit = (event) => {
        const { name, value } = event.target
        props.setState({
            ...props.state,
            [name]: value
        })
    }


    return (
        <Form.Group as={Row}>
            {valid ? " " :
                <Form.Label column sm={4}>{props.caplabel}</Form.Label>
            }
            <Col sm={8}>
                {/* -------------------- /api/contact/contact/ - repair_stage_id -------------------- */}
                {props.defaultSwitch ?

                    <Form.Control
                        readOnly={true}
                        plaintext={true}
                        value={props.fieldwritten ? props.fieldwritten : ''}
                    />

                    :
                    <Form.Control
                        disabled={props.defaultSwitch}
                        plaintext={props.defaultSwitch}
                        name={props.label}
                        value={props.field ? props.field : ""}
                        onChange={handleEdit}
                        as="select">
                        <option value="" />
                        {props?.selectOptions?.length > 0 &&
                            props?.selectOptions.map(data => (
                                <option value={data.value ? data.value : data.id} key={data.value}>{data.display_name ? data.display_name : data.first_name}</option>
                            ))
                        }
                    </Form.Control>
                }
            </Col>
        </Form.Group>
    )
}



export const GeneralSelectField = (props) => {
    let valid = props.label === undefined

    // console.log("props.selectOptions", props.selectOptions)

    const getColor = (e) => {
        const idx = e.target.selectedIndex;
        const option = e.target.querySelectorAll('option')[idx];
        const color = option.getAttribute('data');
        return color
    }

    const handleEdit = (event, action) => {
        // console.log("miku", event, action)
        const { name, value } = event.target
        const data = JSON.parse(getColor(event))
        // console.log(data, "yello")
        if (props.setAll) {
            props.setState({
                ...data
            })
            return
        }

        props.setState({
            ...props.state,
            [name]: value
        })

        // console.log("waki", props.selectOptions)


        if (props.setAll) {
            props.setState(
                {
                    ...props.state,
                    [name]: value,

                    ...event.target.key
                }
            )
        }
    }


    return (
        <Form.Group as={Row}>
            {valid ? " " :
                <Form.Label column sm={4}>{props.caplabel}</Form.Label>
            }
            <Col sm={8}>
                {/* -------------------- /api/contact/contact/ - repair_stage_id -------------------- */}
                {props.defaultSwitch ?

                    <Form.Control
                        readOnly={true}
                        plaintext={true}
                        value={props.fieldwritten ? props.fieldwritten : ''}
                    />

                    :



                    <Form.Control
                        disabled={props.defaultSwitch}
                        plaintext={props.defaultSwitch}
                        name={props.label}
                        value={props.field ? props.field : ""}
                        onChange={handleEdit}
                        as="select">
                        <option disabled value="">Select an Option</option>
                        {props?.selectOptions &&
                            props?.selectOptions.map(data => (
                                <option value={data[props.value]} data={JSON.stringify(data)}>{data[props.show]}</option>
                            ))
                        }
                    </Form.Control>


                }
            </Col>
        </Form.Group>
    )
}

export const ReactGeneralSelect = (props) => {
    // console.log("mew",props.options)
    const handleEdit = (event, action) => {
        const { name } = action
        const { id } = event
        props.setState(
            { ...props.state, [name]: id }
        )
        // console.log("mew", event, action, props.state)
    }


    return (


        <>
            {props.defaultSwitch ?
                // <div style={{width:'100%',display:'flex',justifyContent:'space-between',alignItems:"center"}}>
                <div className="form-group row">
                    <Form.Label column sm={4}>{props.label}</Form.Label>

                    {/*<Form.Label style={{width:"25%"}}>{props.label}</Form.Label>*/}

                    <Form.Control
                        readOnly={true}
                        plaintext={true}
                        style={{ width: '65%', marginBottom: '7px' }}
                        value={props.value ? props.value : ''}
                    />
                </div>

                :
                <>
                    {/*<div style={{display:'flex',width:'100%',justifyContent:'space-between',alignItems:'center',marginBottom:'10px'}}>*/}
                    <div className="form-group row">
                        <Form.Label column sm={4}>{props.label}</Form.Label>
                        {/*<label style={{width:"25%"}}>{props.label}</label>*/}
                        <Col sm={props.size || 8}>

                            <Select
                                name={props.name}
                                defaultValue={props.value ? { label: props.value } : ""}
                                onChange={handleEdit}
                                options={props.options}
                                getOptionValue={option => option.label}
                            />
                        </Col>
                    </div>

                </>


            }


        </>


    )
}

export const AddressField = (props) => {
    const [addressState, setAddressState] = useState([]);
    const [address, setAddress] = useState({ id: props?.field, label: props?.full_address });

    const loadAddresses = () => {
        getAddressDropdown()
           .then(data => setAddressState(data))
    };

    useEffect(() => {
        if (!props.nonEditMode) {
            loadAddresses();
        }
        setAddress({ id: props?.field, label: props?.full_address });
    }, [props.nonEditMode]);

    const handleEdit = (event, action) => {
        const { name } = action;
        const { id, label } = event;
        props.setState((prev) => ({
            ...prev,
            [name]: id
        }));
        setAddress({ id, label });
    };

    const newAddress = (id, address) => {
        props.setState((prev) => ({
            ...prev,
            address_id: id
        }));
        setAddress({ id: id, label: address });
        loadAddresses();
    };

    return (
        <Form.Group as={Row}>
            <Form.Label column sm={4}>Address</Form.Label>
            {props.nonEditMode ? (
                <Col sm={8}>
                    <Form.Control
                        readOnly={true}
                        plaintext={true}
                        value={props.full_address ? props.full_address : ''}
                    />
                </Col>
            ) : (
                <Col lg={6}>
                    <Select
                        name={props.name || 'address_id'}
                        placeholder={"Select an address"}
                        aria-placeholder={'type to find an existing address or add a new one with the plus!'}
                        value={address}
                        onChange={handleEdit}
                        options={mapOptions(addressState, "id", "display_address")}
                        getOptionValue={option => option.label}
                        isLoading={addressState.length === 0} // Show loading state if addressState is empty
                    />
                </Col>
            )}
            {props.nonEditMode ? (
                <Col />
            ) : (
                <>
                    <AddressModal address_id={address?.id} newAddress={newAddress} />
                </>
            )}
        </Form.Group>
    );
};


export const AppStatusField = (props) => {
    const [appStatusState, setAppStatusState] = useState([]);
    const [appStatus, setAddress] = useState({ id: props?.field, label: props?.full_address });

    const loadStatus = async () => {
        const appStatuses = await Addresses.getAddressesv1();
        setAppStatusState(appStatuses);
    };

    useEffect(() => {
        if (!props.nonEditMode) {
            loadStatus();
        }
        setAddress({ id: props?.field, label: props?.full_address });
    }, [props.nonEditMode]);

    const handleEdit = (event, action) => {
        const { name } = action;
        const { id, label } = event;
        props.setState((prev) => ({
            ...prev,
            [name]: id
        }));
        setAddress({ id, label });
    };

    const newStatus = (id, address) => {
        props.setState((prev) => ({
            ...prev,
            address_id: id
        }));
        setAddress({ id: id, label: address });
        loadStatus();
    };

    return (
        <Form.Group as={Row}>
            <Form.Label column sm={4}>Application Status</Form.Label>
            {props.nonEditMode ? (
                <Col sm={8}>
                    <Form.Control
                        readOnly={true}
                        plaintext={true}
                        value={props.full_address ? props.full_address : ''}
                    />
                </Col>
            ) : (
                <Col lg={6}>
                    <Select
                        name="address_id"
                        placeholder={"Select an address"}
                        aria-placeholder={'type to find an existing appStatus or add a new one with the plus!'}
                        value={appStatus}
                        onChange={handleEdit}
                        options={mapOptions(appStatusState, "id", "status")}
                        getOptionValue={option => option.label}
                        isLoading={appStatusState.length === 0} // Show loading state if addressState is empty
                    />
                </Col>
            )}
            {props.nonEditMode ? (
                <Col />
            ) : (
                <>
                    <AddressModal address_id={appStatus?.id} newAddress={newStatus} />
                </>
            )}
        </Form.Group>
    );
};



export const GeneralSelectFieldWTAH = (props) => {

    const {
        defaultOption,
        mapState,
        state,
        setState,
        label,
        show,
        wat,
        name,
        placeholder,
        valueName,
        idName
    } = props;

    // console.log(props)

    var initial = [];
    if (defaultOption) {
        initial = [{ label: defaultOption, id: "card" }, { label: "Connect To Bank Account", id: "bank" }]
    }

    const handleEdit1 = (event, action) => {
        // console.log("GeneralSelectFieldWTAHEdit", event, action)
        const { id } = event
        const { name } = action

        setState(
            { ...state, [name]: id }
        )
    }

    return (
        <Form.Group as={Row}>

            <Form.Label className={"mb-n5"} column sm={4}>{label}</Form.Label>

            {
                props.defaultSwitch ?
                    <Col sm={8}>
                        <Form.Control
                            readOnly={true}
                            plaintext={true}
                            value={show ? show : ''}
                        />
                    </Col>
                    :
                    <Col sm={8}>
                        <Select
                            isSearchable
                            name={name}
                            defaultValue={show && wat ? {label: show} : {id: show}}
                            onChange={handleEdit1}
                            className={""}
                            placeholder={placeholder ? placeholder : ''}
                            options={[...initial, ...mapOptions(mapState, idName, valueName)]}
                            getOptionValue={option => option.label}
                        />
                    </Col>
            }
        </Form.Group>
    )
}

export const BankCreditSelect = (props) => {
    const [paymentMethod, setPaymentMethod] = useState({});

    // console.log({paymentMethod})

    // function handleNewCard() {
    //     console.log(paymentMethod)
    //     const paymentMethodData = {id: paymentMethod.id, label: paymentMethod.card_last_four}
    //     setPaymentMethod(paymentMethodData)
    //     let cardMethods = props.paymentMethods.filter(item => item.label === 'Cards')[0]
    //     console.log(cardMethods.options)
    //     cardMethods.push(paymentMethodData)
    //     const newMethods = [
    //        ...props.paymentMethods,
    //        cardMethods
    //     ]
    //     console.log(newMethods)
    // }

    function handleNewBank() {
        setPaymentMethod({id: "select", label: "Select..."})
    }

    const groupStyles = {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    };

    const groupBadgeStyles = {
        backgroundColor: '#EBECF0',
        borderRadius: '2em',
        color: '#172B4D',
        display: 'inline-block',
        fontSize: 12,
        fontWeight: 'normal',
        lineHeight: '1',
        minWidth: 1,
        padding: '0.16666666666667em 0.5em',
        textAlign: 'center',
    };
    // JSX
    const formatGroupLabel = (data) => {
        return (
            <main style={groupStyles}>
                <span>{data.label}</span>
                <span style={groupBadgeStyles}>{data.options.length}</span>
            </main>
        )
    }

    const createCustomerBool = () => {
        return props.paymentMethods.some((item) => item.label !== "Cards")
    }

    useEffect(() => {
        console.log(paymentMethod)
        // if (paymentMethod.id === 'newCard') {
        //     console.log('here')
        //     handleNewCard()
        // } else if (paymentMethod.id === 'newBank') {
        //     handleNewBank()
        // } else
            if (paymentMethod.id !== "select") {
            if (props.paymentMethods)
            props.setState({
                ...props.state,
                [props.name]: paymentMethod.id,
                type:paymentMethod.type,
            })
        }
    }, [paymentMethod]);

    // useEffect(() => {
    //     console.log(props.paymentMethods)
    //
    // }, [props.paymentMethods]);

    return (
        <Form.Group as={Row}>
            <Form.Label className={"mb-n5"} column sm={4}>{props.label}</Form.Label>
            <Col sm={8}>
                <Select
                    name="paymentMethod"
                    options={props.paymentMethods}
                    getOptionLabel={option => option.label}
                    getOptionValue={option => option.id}
                    onChange={(data) => {
                        console.log(data)
                        setPaymentMethod(data)
                    }}

                    formatGroupLabel={formatGroupLabel}
                />
                <Row>
                    <Col md={12}>
                        {
                            paymentMethod.id === 'newCard' &&
                            <CreateCard
                                createCustomer={createCustomerBool}
                                loanState={props.loanState}
                                reloadAccounts={props.reloadAccounts}
                                setPaymentMethod={setPaymentMethod}
                            />
                        }
                        {
                            paymentMethod.id === 'newBank' &&
                            <Row
                                style={{
                                    borderTop: '1px solid lightgray',
                                }}
                            >
                                <StripeBase
                                    contactData={props.loanState.contact_id}
                                    method={'create'}
                                    context={{reloadAccounts: props.reloadAccounts}}
                                />
                            </Row>
                        }
                    </Col>
                </Row>
            </Col>
        </Form.Group>
    );
}

export const GeneralSelectFieldWlink = (props) => {
    const history = useHistory()

    var initial = [];
    if (props.defaultOption) {
        initial = [{ label: props.defaultOption, id: "card" }]
    }
    var mapState = props.mapState



    const handleEdit1 = (event, action) => {
        const { name } = action
        const { id } = event
        // console.log("clicked", name, id)
        if (id === "card") {
            props.defaultOptionFunctionCall()
            return
        }
        // if(id=="bank"){
        //     props.defaultOptionFunctionCall()
        //     return
        // }
        props.setState(
            { ...props.state, [name]: id }
        )

    }


    return (
        <Form.Group as={Row}>

            <Form.Label className={"mb-n5"} column sm={4}>{props.label}</Form.Label>

            {props.defaultSwitch ?
                <>
                    <Col sm={6}>
                        <Form.Control

                            readOnly={true}
                            plaintext={true}
                            value={props.show ? props.show : ''}
                        />
                    </Col>

                    <Col sm={2}
                         onClick={() => props.state.trust_id && history.push(`../${props.link}/${props.linkId}`)}
                         style={{ marginLeft: "-20", display: 'flex', alignItems: 'center' }}>
                        {
                            props.defaultSwitch && <FaExternalLinkAlt />
                        }
                    </Col>
                </>
                :
                <Col sm={8}>
                    <Select
                        name={props.name}
                        defaultValue={props.show && props.wat ? { label: props.show } : { id: props.show }}
                        onChange={handleEdit1}
                        className={""}
                        options={[...initial, ...mapOptions(mapState, props.idName, props.valueName)]}
                        getOptionValue={option => option.label}
                    />
                </Col>
            }
        </Form.Group>
    )
}

export const PhoneField = (props) => {
    let valid = props.options === undefined
    const handleValueChange = (e, string) => {
        // console.log("Event", e, "name", string)
        let name = props.name
        let value = "+" + string
        props.setState({
            ...props.state,
            [name]: value
        })
    }

    return (
        <Form.Group as={Row}
            // style={{overflow:'auto'}}
        >
            {valid ? " " :
                <Form.Label column sm={4}>{props.options.label}</Form.Label>
            }
            <Col sm={props.defaultSwitch ? 6 : 8} className={"mb-0"}>
                {
                    props.defaultSwitch ?
                        <NumberFormat
                            value={props.field ? props.field : ''}
                            readOnly={true}
                            className="form-control-plaintext"
                            thousandSeparator={false}
                            allowNegative={false}
                            format="+# (###) ###-####"
                            isNumericString={false}
                            name="insured_amount"
                        />
                        :
                        <PhoneInput
                            inputStyle={{
                                width: "100%",
                                height: 43,
                                fontSize: 13,
                                backgroundColor: "#F4F7FA",
                                borderRadius: 5,
                                paddingLeft: 45,
                            }}

                            field={props.name}
                            country={'us'}
                            value={props.field ? props.field : ''}
                            // value={subContact.phone ? subContact["phone"] : ''}
                            placeholder="phone"
                            onChange={(string, b, e,) => handleValueChange(e, string)}
                        />
                }
            </Col>
            {props.field && props.defaultSwitch && (
                <Col
                    sm={1}
                    onClick={() => window.open(`tel:${props.field}`)}
                    className="mouse-cursor"
                    style={{ marginTop: 10, marginLeft: valid ? '-25px' : '-10px' }}
                >
                    <OverlayTrigger
                        overlay={
                            <Tooltip>
                                Call
                            </Tooltip>
                        }
                    >
                        <i  className="fa fa-phone mouse-cursor fa-1x pointer" aria-hidden="true" />
                    </OverlayTrigger>
                </Col>

            )}
            {
               props.state?.phone_alert && props.defaultSwitch &&
               <Col
                  sm={1}
                  className="mouse-cursor"
                  style={{ marginTop: 10, marginLeft: valid ? '-25px' : '-10px' }}
               >
                   <OverlayTrigger
                      overlay={
                          <Tooltip>
                              Phone number needs to be updated!
                          </Tooltip>
                      }
                   >
                       <i  className="fa fa-exclamation-triangle mouse-cursor fa-1x pointer" aria-hidden="true" />
                   </OverlayTrigger>
               </Col>
            }

            {/*this part is here just for subcontact list*/}

        </Form.Group>
    )
}

export const PhoneFieldSub = (props) => {
    let valid = props.options === undefined
    const handleValueChange = (e, string) => {
        // console.log("Event", e, "name", string)
        let name = props.name
        let value = "+" + string
        props.setState({
            ...props.state,
            [name]: value
        })
    }

    return (
        <Col sm={props.defaultSwitch ? 12 : 10}>
            <Form.Group as={Row}
                // style={{overflow:'auto'}}
            >
                {valid ? " " :
                    <Form.Label className={"mb-n2"} style={{ minWidth: 75 }} column sm={props.sub ? 2 : 4}>{props.options.label}</Form.Label>
                }
                <Col sm={valid ? 8 : props.sub ? 5 : 6} className={props.sub ? "mb-n5 p-0" : "mb-n5"}>
                    {
                        props.defaultSwitch ?
                            <NumberFormat
                                value={props.field ? props.field : ''}
                                readOnly={true}
                                className="form-control-plaintext"
                                thousandSeparator={false}
                                allowNegative={false}
                                format="+# (###) ###-####"
                                isNumericString={false}
                                name="insured_amount"
                            />
                            :
                            <PhoneInput
                                inputStyle={{
                                    width: "100%",
                                    height: 43,
                                    fontSize: 13,
                                    backgroundColor: "#F4F7FA",
                                    borderRadius: 5,
                                    paddingLeft: 45,
                                }}

                                field={props.name}
                                country={'us'}
                                value={props.field ? props.field : ''}
                                // value={subContact.phone ? subContact["phone"] : ''}
                                placeholder="phone"
                                onChange={(string, b, e,) => handleValueChange(e, string)}
                            />
                    }
                </Col>
                {props.field && props.defaultSwitch && <Col sm={1}
                                                            onClick={() => window.open(`tel:${props.field}`)}
                                                            style={{ marginTop: 10, marginLeft: valid ? '-25px' : '-10px' }}>
                    <OverlayTrigger
                        overlay={
                            <Tooltip>
                                Call
                            </Tooltip>
                        }
                    >
                        <i class="fa fa-phone fa-1x" aria-hidden="true"></i>
                    </OverlayTrigger>

                </Col>}
                {/*this part is here just for subcontact list*/}
                {props.sub && <Col>
                    <Form.Control
                        readOnly={props.defaultSwitch}
                        className="text-capitalize"
                        name="description"
                        plaintext={props.defaultSwitch}
                        value={props.state.description ? props.state["description"] : ''}
                        placeholder="Description"
                        onChange={props.descriptionChange}
                    />
                </Col>}
            </Form.Group>
        </Col>
    )
}

export const EmailField = (props) => {
    let valid = props.options === undefined

    const handleEdit = (event) => {
        // console.log("Event", event)
        const { name, value } = event.target
        props.setState({
            ...props.state,
            [name]: value
        })
    }

    return (
        <Form.Group as={Row}>
            {valid ? " " :
                <Form.Label column sm={4}>{props.options.label}</Form.Label>
            }
            <Col sm={props.defaultSwitch ? 6 : 8} className={"mb-0"}>
                <Form.Control
                    readOnly={props.defaultSwitch}
                    name={props.name}
                    plaintext={props.defaultSwitch}
                    defaultValue={props.field ? props.field : ''}
                    onChange={handleEdit}
                    placeholder="name@example.com"
                />
            </Col>
            {props.field && props.defaultSwitch &&
                <Col sm={1}
                     onClick={() => window.open(`https://mail.google.com/mail/?view=cm&source=mailto&to=${props.field}`, "_blank")}
                     style={{ marginTop: 10, marginLeft: valid ? '-25px' : '-10px' }}
                     className='mouse-cursor'
                >
                    <OverlayTrigger
                        overlay={
                            <Tooltip>
                                Email
                            </Tooltip>
                        }
                    >
                        <i class="fa fa-envelope mouse-cursor fa-1x" aria-hidden="true"/>
                    </OverlayTrigger>
                </Col>
            }
            {
               props.state?.email_alert && props.defaultSwitch &&
               <Col
                  sm={1}
                  className="mouse-cursor"
                  style={{ marginTop: 10, marginLeft: valid ? '-25px' : '-10px' }}
               >
                   <OverlayTrigger
                      overlay={
                          <Tooltip>
                              Email needs to be updated!
                          </Tooltip>
                      }
                   >
                       <i  className="fa fa-exclamation-triangle mouse-cursor fa-1x pointer" aria-hidden="true" />
                   </OverlayTrigger>
               </Col>
            }
            {/*this part is here just for subcontact list*/}
            {props.sub && <Col>
                <Form.Control
                    readOnly={props.defaultSwitch}
                    className="text-capitalize"
                    name="description"
                    plaintext={props.defaultSwitch}
                    value={props.state.description ? props.state["description"] : ''}
                    placeholder="Description"
                    onChange={props.descriptionChange}
                />
            </Col>}

        </Form.Group>
    )
}

export const EmailFieldSub = (props) => {
    let valid = props.options === undefined

    const handleEdit = (event) => {
        // console.log("Event", event)
        const { name, value } = event.target
        props.setState({
            ...props.state,
            [name]: value
        })
    }

    return (
        <Col sm={props.defaultSwitch ? 12 : 10}>
            <Form.Group as={Row}>
                {valid ? " " :
                    <Form.Label style={{ minWidth: 75 }} column sm={props.sub ? 2 : 4}>{props.options.label}</Form.Label>
                }
                <Col sm={valid ? 8 : props.sub ? 5 : 6} className={props.sub ? "mb-n5 px-0" : "mb-n5"}>
                    <Form.Control
                        readOnly={props.defaultSwitch}
                        name={props.name}
                        plaintext={props.defaultSwitch}
                        defaultValue={props.field ? props.field : ''}
                        onChange={handleEdit}
                        placeholder="name@example.com"
                    />
                </Col>
                {props.field && props.defaultSwitch && <Col sm={1}
                                                            onClick={() => window.open(`https://mail.google.com/mail/?view=cm&source=mailto&to=${props.field}`, "_blank")}
                                                            style={{ marginTop: 10, marginLeft: valid ? '-25px' : '-10px' }}>
                    <OverlayTrigger
                        overlay={
                            <Tooltip>
                                Email
                            </Tooltip>
                        }
                    >
                        <i class="fa fa-envelope fa-1x" aria-hidden="true"></i>
                    </OverlayTrigger>
                </Col>
                }
                {/*this part is here just for subcontact list*/}
                {props.sub && <Col>
                    <Form.Control
                        readOnly={props.defaultSwitch}
                        className="text-capitalize"
                        name="description"
                        plaintext={props.defaultSwitch}
                        value={props.state.description ? props.state["description"] : ''}
                        placeholder="Description"
                        onChange={props.descriptionChange}
                    />
                </Col>}

            </Form.Group>
        </Col>
    )
}

//this field displays a muted switch when the field is not editable then shows editable switches otherwise,
// it will display the label to the far right and the switch to the far right.

export const GeneralFilter = (props) => {


    var filterOptions = [
        { label: "All", id: '' },
        { label: "Active", id: 'active' },
        { label: "Vacant", id: 'vacant' },
        { label: "Sold LC", id: 'SL' },
        { label: "Not Under Man", id: 'NUM' },
        { label: "With Investor", id: 'investor' },
        { label: "With Family", id: 'family' },
        { label: "Without Investor", id: '!investor' },
        { label: "Without Family", id: '!family' },
        { label: "Archived", id: 'archived' }
    ]
    if (props?.options) {
        filterOptions = props.options
    }

    const CustomStyle = {
        option: (base, state) => ({
                ...base,
                textAlign: 'center'
            }
        ),
        valueContainer: (baseStyles, state) => ({
            ...baseStyles,
            textAlign: 'center'
        }),
        control: (styles) => ({
            ...styles,
            borderRadius: '0%'
        })
    }

    const onFilterChange = (e) => {
        if (props.functionToRun) {
            props.functionToRun(e)
            return
        }
        // console.log("you pressed this", e.id)
        props.setState(e.id)
    }

    return (
        <Select
            name={props.name}
            // defaultValue={props.show&&props.wat?{label:props.show}:{id:props.show}}
            onChange={onFilterChange}
            isSearchable={false}
            className={""}
            defaultValue={{ label: <><i style={{ margin: '0px 5px 0px 5px' }} class="fa fa-filter"></i><span>Filter</span></>, value: "" }}
            // menuIsOpen={true}
            styles={CustomStyle}
            placeholder="Filter"
            options={filterOptions}
            getOptionValue={option => option.label}
        />
    )


}

export const SwitchField = (props) => {
    let valid = props.options === undefined

    const handleEdit = (event) => {
        // console.log("EVENT in")
        // console.log("Event", event)
        const { name } = event.target
        if (props.number) {
            if (parseInt(props.state[name]))
                props.setState({
                    ...props.state,
                    [name]: 0
                })
            else {
                props.setState({
                    ...props.state,
                    [name]: 1
                })
            }
            return
        }
        props.setState({
            ...props.state,
            [name]: !props.field
        })
    }
    // console.log("asfdgasdgf",props.options.read_only)
    return (
        <div>
            {valid ? " " :
                <Form.Label >{props.options.label}</Form.Label>
            }



            <div className="switch  m-l-10 float-right">

                {props.defaultSwitch ? (
                    <Form.Control
                        type="checkbox"
                        id={props.name}
                        name={props.name}
                        defaultChecked={props.field}
                        onChange={handleEdit}
                        disabled={true}
                    />
                ) : (
                    <Form.Control
                        type="checkbox"
                        id={props.name}
                        name={props.name}
                        defaultChecked={props.field}
                        onChange={handleEdit}
                    />
                )}

                <Form.Label htmlFor={props.name} className="cr float-right" />
            </div>
        </div>
    )
}

export const TagsField = ({ tags, suggestions, onDelete, onAddition, defaultSwitch, options }) => {
    let valid = options === undefined
    const reactTags = useRef()
    return (
        <Form.Group as={Row}>
            {valid ? " " :
                <Form.Label className={"mb-0 mt-0"} column sm={4}>{options.label}</Form.Label>
            }
            <Col sm={8}>
                {
                    defaultSwitch ? (<div className=" mt-2">
                                {tags.map(tag => <span className="react-tags bootstrap-tagsinput p-1 mx-1">{tag.name}</span>)}
                            </div>
                        )
                        :
                        <ReactTags
                            classNames={{ root: 'react-tags bootstrap-tagsinput pl-3', selectedTag: 'react-tags__selected-tag btn-primary' }}
                            ref={reactTags}
                            tags={tags}
                            suggestions={suggestions}
                            minQueryLength={0}
                            onDelete={onDelete}
                            onAddition={onAddition}

                        />
                }
            </Col>
        </Form.Group>

    )
}