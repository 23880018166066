import React, {useEffect, useState} from "react";

import { Row, Col } from 'react-bootstrap';
import {loadStripe} from "@stripe/stripe-js";
import {Elements} from "@stripe/react-stripe-js";

import LoadingHand from "../../Loader/LoadingHand";
import CreateBank from "./CreateBank";

import {CONFIG} from "../../../config/constant";

import {setupIntentUrl,} from "../../../config/Endpoints";

import {postData} from "../../../services/endpointService";


// console.log(CONFIG.stripeKey)
const stripePromise = loadStripe(CONFIG.stripeKey)


const Content = ({method, contactData, setupIntent, context}) => {

    return (
       <>
           {
              method === 'create' &&
              <CreateBank
                 contactData={contactData}
                 intent={setupIntent}
                 context={context}
              />
           }
       </>
    )
}

const StripeContent = ({ contactData, method, context }) => {
    const [clientSecret, setClientSecret] = useState(false);
    const [setupIntent, setSetupIntent] = useState({})

    function getSetupIntent() {
        if (contactData.id) {
            postData(
               setupIntentUrl,
               {
                   contact_id: contactData.id
               },
               true
            ).then((res) => {
                // console.log(res.data)
                setSetupIntent(res.data)
                setClientSecret(res?.data?.client_secret)
            })
        }
    }

    const appearance = {
        theme: 'stripe',
    };
    const options = {
        clientSecret,
        appearance,
    };

    // console.log({setupIntent,clientSecret,options,contactData})

    // useEffect(() => {
    //     if (setupIntent && Object.keys(setupIntent).includes('client_secret')) {
    //         console.log({setupIntent},1)
    //         setClientSecret(setupIntent.client_secret)
    //     }else {
    //         console.log("setupIntent false")
    //     }
    // }, [setupIntent]);

    useEffect(() => {
        if (Object.keys(contactData)?.length > 0) {
            getSetupIntent()
        }
    }, [contactData]);

    return (
       <Row>
           {
               !options.clientSecret
                  ?
                  <LoadingHand />
                  :
                  (
                     <Elements stripe={stripePromise} options={options}>
                         <Content
                            method={method}
                            contactData={contactData}
                            setupIntent={setupIntent}
                            context={context}
                         />
                     </Elements>
                  )
           }
       </Row>
    );
}

const StripeBase = ({ contactData, method, context }) => {
    // console.log({contactData})
    return    (
       <Elements stripe={stripePromise}>
           <StripeContent method={method} contactData={contactData} context={context}/>
       </Elements>
    )
}

export default StripeBase;