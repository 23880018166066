export const BASENAME = ''; // don't add '/' at end off BASENAME
export const BASE_URL = '/trust/list';
export const BASE_TITLE = 'Equity & Help Matrix 2';
export const API_SERVER = process.env.REACT_APP_API_SERVER || 'https://eh-test-api.herokuapp.com/'
export const GOOGLE_MAP_API_KEY='AIzaSyCH-X1dLHxoVSxSS9ynLqAxfhdDnrmY9jc'
// export const API_SERVER = 'http://127.0.0.1:8000/';

let stripeKey = 'pk_test_51J2k6YL5azqkiIOx1TMFdLu9BhlU3esDSK10bnmQyRY2kzuviXog6TRTd7D9rqMWRBAzBXtLRpAfJJ0Zoonl9fyT00IWC2bCkX'
if (process.env.REACT_APP_API_SERVER === 'https://eh-prod-api.herokuapp.com/') {
    stripeKey = 'pk_live_51J2k6YL5azqkiIOxgkN1BVYOvbr6SlVmAplJEDRujvajLcJU1nIUz1d3CLDbbAZEQefbR4PBkwwBxS0H4DOMAZgj00p4iXlMaZ'
}

export const CONFIG = {
    layout: 'vertical', // vertical, horizontal
    subLayout: '', // null, layout-2, layout-2-2, layout-3, layout-4, layout-4-2, layout-6, layout-8
    collapseMenu: true, // mini-menu
    layoutType: 'menu-dark', // menu-dark, menu-light, dark
    navIconColor: false,
    headerBackColor: 'header-default', // header-default, header-blue, header-red, header-purple, header-lightblue, header-dark
    navBackColor: 'navbar-default', // navbar-default, navbar-blue, navbar-red, navbar-purple, navbar-lightblue, navbar-dark
    navBrandColor: 'brand-default', // brand-default, brand-blue, brand-red, brand-purple, brand-lightblue, brand-dark
    navBackImage: false, // false, navbar-image-1, navbar-image-2, navbar-image-3, navbar-image-4, navbar-image-5
    rtlLayout: false,
    navFixedLayout: true, // only for vertical layouts
    headerFixedLayout: false, // only for vertical layouts
    boxLayout: false,
    navDropdownIcon: 'style1', // style1, style2, style3
    navListIcon: 'style1', // style1, style2, style3, style4, style5, style6
    navActiveListColor: 'active-default', // active-default, active-blue, active-red, active-purple, active-lightblue, active-dark
    navListTitleColor: 'title-default', // title-default, title-blue, title-red, title-purple, title-lightblue, title-dark
    navListTitleHide: false,
    configBlock: true,
    layout6Background: 'linear-gradient(to right, #A445B2 0%, #D41872 52%, #FF0066 100%)', // used only for pre-layout = layout-6
    layout6BackSize: '', // 'auto' - for background pattern, 'cover' - for background images & used only for pre-layout = layout-6
    API_SERVER,
    stripeKey: stripeKey
    // API_SERVER: 'http://127.0.0.1:8000/',
};

// console.log(CONFIG)
