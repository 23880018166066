
import CreateContact from './pages/contact/CreateContact';
import UserRequestForm from './pages/portal/UserRequestForm';

const menuItems = {
   investor: [
      {
         id: 'inv-nav',
         title: "Navigation",
         type: 'group',
         icon: 'icon-navigation',
         children: [
            // {
            //    id: 'inv-dash',
            //    title: 'Dashboard',
            //    type: 'item',
            //    icon: 'feather icon-sidebar',
            //    url: '/investor-portal'
            // },
            {
               id: 'inv-monthly',
               title: 'Monthly Data',
               type: 'item',
               icon: 'feather icon-bar-chart-2',
               url: '/investor-portal/monthly'
            },
            {
               id: 'inv-yearly',
               title: 'Yearly Data',
               type: 'item',
               icon: 'feather icon-bar-chart-2',
               url: '/investor-portal/yearly'
            },
            // {
            //    id: 'inv-report',
            //    title: 'Report',
            //    type: 'item',
            //    icon: 'feather icon-clipboard',
            //    url: '/investor-portal/report'
            // },
            {
               id: 'inv-marketing',
               title: 'Marketing',
               type: 'item',
               icon: 'feather icon-globe',
               url: '/investor-portal/marketing'
            },
            {
               id: 'inv-request',
               title: 'Requests',
               type: 'item',
               icon: 'feather icon-globe',
               url: '/investor-portal/requests'
            },
         ]
      }
   ],
   items: [
      {
         id: 'navigation',
         title: 'Navigation',
         type: 'group',
         icon: 'icon-navigation',
         children: [
            {
               id: 'dash',
               title: 'Dashboard',
               type: 'item',
               icon: 'feather icon-sidebar',
               url: '/admin/dashboard'
            },
            {
               id: 'acquisitions',
               title: 'Acquisitions',
               type: 'collapse',
               icon: 'feather icon-shopping-cart',
               url: '/acquisitions/list',
               children: [
                  // {
                  //    id: 'newScrape',
                  //    title: 'Start New Scrape',
                  //    type: 'item',
                  //    url: '/acquisitions/scrape',
                  // },
                  // {
                  //    id: 'scrapeList',
                  //    title: 'Scrape List ',
                  //    type: 'item',
                  //    url: '/acquisitions/scrape/list',
                  // },
                  {
                     id: 'acquistionsList',
                     title: 'Acquisition List',
                     type: 'item',
                     url: '/acquisitions/list',
                  },
                  {
                     id: 'disapprovedList',
                     title: 'Disapproved List',
                     type: 'item',
                     url: '/acquisitions/disapproved',
                  },
               ],
            },
            {
               id: 'trust',
               title: 'Trust',
               type: 'collapse',
               icon: 'feather icon-home',
               url: '/trust/list',
               children: [
                  {
                     id: 'trust',
                     title: 'Trust List',
                     type: 'item',
                     url: '/trust/list',
                  },
                  // {
                  //    id: 'trust-kanban',
                  //    title: 'Trust Kanban',
                  //    type: 'item',
                  //    url: '/trust/kanban',
                  // },
                  {
                     id: 'trust-create',
                     title: 'Create New Trust',
                     type: 'item',
                     url: '/trust',
                  },
               ],
            },
            {
               id: 'investor-menu',
               title: 'Investor CRM',
               type: 'collapse',
               icon: 'feather icon-users',
               url: '/investor/',
               children: [
                  {
                     id: 'investorlead',
                     title: 'Investor Lead List',
                     type: 'item',
                     url: '/investorlead/list',
                  },
                  // {
                  //    id: 'Investor-lead-create',
                  //    title: 'Investor Lead Create',
                  //    component: () => (
                  //       <CreateContact name="Investor Lead" isNavbar />
                  //    ),
                  //    type: 'item',
                  //    url: '/investor/create',
                  // },
                  {
                     id: 'investor',
                     title: 'Investor List',
                     type: 'item',
                     url: '/investor/list',
                  },
                  // {
                  //    id: 'investor-create',
                  //    title: 'Investor Create',
                  //    component: () => (
                  //       <CreateContact name="Investor" isNavbar createAccount/>
                  //    ),
                  //    type: 'item',
                  //    url: '/investor/create',
                  // },
                  {
                     id: 'trusts-rtp',
                     title: 'Proposals',
                     type: 'item',
                     url: '/rtp',
                  },
                  {
                     id: 'request-list',
                     title: 'Investor Requests',
                     type: 'item',
                     url: '/user-requests/investor/',
                  },
                  {
                     id: 'request-create',
                     title: 'Create Investor Request',
                     type: 'item',
                     url: '/user-requests/investor/create',
                  },
               ],
            },
            {
               id: 'ambassador',
               title: 'Ambassador',
               type: 'collapse',
               icon: 'feather icon-users',
               url: '/ambassador/list',
               children: [
                  {
                     id: 'ambassadorlist',
                     title: 'Ambassador List',
                     type: 'item',
                     url: '/ambassador/list',
                  },
                  // {
                  //    id: 'ambassadorKanban',
                  //    title: 'Ambassador Kanban',
                  //    type: 'item',
                  //    url: '/ambassador/kanban',
                  // },

                  // {
                  //    id: 'ambassadorCreate',
                  //    title: 'Create Ambassador',
                  //    component: () => (
                  //       <CreateContact name="Ambassador" isNavbar />
                  //    ),
                  //    type: 'item',
                  //    url: '/ambassadorCreate',
                  // },
               ],
            },
            {
               id: 'family',
               title: 'Family CRM',
               type: 'collapse',
               icon: 'feather icon-users',
               url: '/family',
               children: [
                  {
                     id: 'familylead',
                     title: 'Family Lead List',
                     type: 'item',
                     url: '/familylead/list',
                  },
                  {
                     id: 'familylead-kanban',
                     title: 'Family Lead Kanban',
                     type: 'item',
                     url: '/familylead/kanban',
                  },
                  {
                     id: 'familylead-Application',
                     title: 'Applications',
                     type: 'item',
                     url: '/applications',
                  },
                  // {
                  //    id: 'family Lead-create',
                  //    title: 'Family Lead Create',
                  //    component: () => (
                  //       <CreateContact name="Family Lead" isNavbar />
                  //    ),
                  //    type: 'item',
                  //    // url: '/investor/create',
                  // },
                  {
                     id: 'family',
                     title: 'Family List',
                     type: 'item',
                     url: '/family/list',
                  },
                  // {
                  //    id: 'family-create',
                  //    title: 'Create Family',
                  //    component: () => <CreateContact name="Family" isNavbar createAccount />,
                  //    type: 'item',
                  //    url: '/family/',
                  // },
                  {
                     id: 'request-list',
                     title: 'Family Requests',
                     type: 'item',
                     url: '/user-requests/family',
                  },
                  {
                     id: 'request-create',
                     title: 'Create Family Request',
                     type: 'item',
                     url: '/user-requests/family/create',
                  },
               ],
            },
            {
               id: 'loans',
               title: 'Loans',
               type: 'collapse',
               icon: 'feather icon-award',
               url: '/loans/',
               children: [
                  {
                     id: 'loanlist',
                     title: 'Loan List',
                     type: 'item',
                     url: '/loans/list',
                  },

                  {
                     id: 'createLoan',
                     title: 'Create Loan',
                     type: 'item',
                     url: '/loans/createLoan',
                  },

               ],
            },
            {
               id: 'maps',
               title: 'Maps',
               type: 'item',
               icon: 'feather icon-map',
               url: '/maps',
            },
            {
               id: 'fam-web',
               title: 'Website/FamPortal',
               type: 'collapse',
               icon: 'feather icon-sidebar',
               children: [
                  {
                     id: 'portal',
                     title: 'FamPortal',
                     type: 'item',
                     icon: 'feather icon-sidebar',
                     url: '/famportal',
                     family: true, // for family users to see
                  },
                  {
                     id: 'fam-request',
                     title: 'Requests',
                     type: 'item',
                     icon: 'feather icon-globe',
                     url: '/famportal/requests',
                     family: true,
                  },
                  {
                     id: 'fam-request-create',
                     title: 'Request Create',
                     type: 'item',
                     icon: 'feather icon-globe',
                     url: '/famportal/requests/create',
                     family: true,
                  },
                  {
                     id: 'fam-request-detail',
                     title: 'Request',
                     type: 'item',
                     icon: 'feather icon-globe',
                     url: '/famportal/requests/:id',
                     family: true,
                  },
                  {
                     id: 'site',
                     title: 'Website',
                     type: 'item',
                     icon: 'feather icon-layout',
                     url: '/website/',
                     family: true, // for family users to see
                  },
               ]
            },
            {
               id: 'accounting',
               title: 'Accounting',
               type: 'collapse',
               icon: 'feather icon-layers',
               url: '/accounting',
               children: [
                  {
                     id: 'bankaccounts',
                     title: 'Bank accounts',
                     type: 'item',
                     url: '/accounting/banks/list',
                  },
                  {
                     id: 'masterledger',
                     title: 'Master Ledger',
                     type: 'item',
                     url: '/accounting/masterLedger',
                  },
                  // todo activate this when we will do actual reconcile of the master ledger to the bank accounts
                  //  {
                  //    id: 'reconcile',
                  //    title: 'Reconcile ML To bank',
                  //    type: 'item',
                  //    url: '/accounting/reconcile',
                  // },
                  {
                     id: 'verifypayment',
                     title: 'Verify Payments',
                     type: 'item',
                     url: '/accounting/verifyPayments',
                  },
                  {
                     id: 'accounting-escrow',
                     title: 'Escrow',
                     type: 'item',
                     url: '/accounting/escrow',
                  },
                  {
                     id: 'accounting-wallet',
                     title: 'PI Wallet',
                     type: 'item',
                     url: '/accounting/wallet',
                  },
               ],
            },
            {
               id: 'contact',
               title: 'Contact',
               type: 'collapse',
               icon: 'feather icon-user',
               url: '/contact/list',
               children: [
                  {
                     id: 'contact',
                     title: 'Contact List',
                     type: 'item',
                     url: '/contact/list',
                  },

                  // {
                  //     id: 'contact-kanban',
                  //     title: 'Contact Kanban',
                  //     type: 'item',
                  //     url: '/contact/kanban',
                  // },
                  // {
                  //    id: 'contact-create',
                  //    title: 'Contact Create',
                  //    type: 'item',
                  //    component: () => <CreateContact name="Contact" isNavbar />,
                  //    url: '/contact/create',
                  // },
               ],
            },
            // {
            //    id: 'trust-situations',
            //    title: 'Situations',
            //    icon: 'feather icon-check-square',
            //    // component:()=><BadgeDisplayNavBar />,
            //    type: 'item',
            //    url: '/situations',
            // },

            // {
            //    id: 'address',
            //    title: 'Address',
            //    type: 'collapse',
            //    icon: 'feather icon-map-pin',
            //    url: '/address',
            //    children: [
            //       {
            //          id: 'address',
            //          title: 'Address',
            //          type: 'item',
            //          url: '/address',
            //       },
            //    ],
            // },


            // {
            //    id: 'familyLead',
            //    title: 'Family Lead',
            //    type: 'collapse',
            //    icon: 'feather icon-user-plus',
            //    // url: '/familylead/list',
            //    children: [
            //
            //
            //    ],
            // },

            // {
            //    id: 'investorlead',
            //    title: 'Investor CRM',
            //    type: 'collapse',
            //    icon: 'feather icon-user-plus',
            //    url: '/investorlead/list',
            //    children: [
            //       // {
            //       //    id: 'investorlead-kanban',
            //       //    title: 'Investor CRM Kanban',
            //       //    type: 'item',
            //       //    url: '/investorlead/kanban',
            //       // },
            //       {
            //          id: 'Investor-lead-create',
            //          title: 'Investor Lead Create',
            //          component: () => (
            //             <CreateContact name="Investor Lead" isNavbar />
            //          ),
            //          type: 'item',
            //          url: '/investor/create',
            //       },
            //    ],
            // },

            // {
            //    id: 'requests',
            //    title: 'User Requests',
            //    type: 'collapse',
            //    icon: 'feather icon-layers',
            //    url: '/user-requests',
            //    children: [
            //       {
            //          id: 'request-list',
            //          title: 'Requests List',
            //          type: 'item',
            //          url: '/user-requests',
            //       },
            //       {
            //          id: 'request-create',
            //          title: 'Request Create',
            //          type: 'item',
            //          url: '/user-requests/create',
            //       },
            //    ],
            // },



         ],
      },
   ],
};

export default menuItems;
