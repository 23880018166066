import http from "./httpService";
import {floatFormatterForFamilyLead} from '../components/Custom/floatFormatter.js'
import {fieldTypeContact} from "../config/contact/fieldTypeContact.js"
// Import Endpoints
import {investorLeadUrl,investorLeadStageUrl} from '../config/Endpoints';


const apiEndpoint=`${investorLeadUrl}`

const getInvestorLeads=async(query="")=>{
   try {
       const {data}=await http.get(apiEndpoint+query)
       // console.log("getInvestorLeads",data)
       const parsedList = floatFormatterForFamilyLead(data.results, fieldTypeContact)
       // console.log("getInvestorLeads",data,parsedList)
       return {...data,results:parsedList}
   } catch (error) {
       console.log(error)
       return []
   }

}

const getInvestorLead=async(id,spd)=>{
    if(!id) return {}
    try {
        const {data}=await http.get(`${apiEndpoint}${id}`)
        if(spd) return data
        const parsedList = floatFormatterForFamilyLead(data, fieldTypeContact)
        return parsedList
    } catch (error) {
        console.log(error)
        return {}
    }
 
 }


 const updateInvestorLead=async(id,investorLead)=>{
    try {
       const {data}= await http.patch(`${apiEndpoint}${id}/`,investorLead)
       const parsedList = floatFormatterForFamilyLead(data, fieldTypeContact)
       return parsedList
    } catch (error) {
        console.log(error)
        return
    }
}


const getOptions = async() => {
    try {
        const {data}=await  http.options(`${apiEndpoint}`)
        // console.log(data,'optiond')
        return data.actions.POST.contact.children
      } catch (error) {
          console.log(error)
      }
}


const getStages=async(source)=>{
    try {
        const {data}=await http.get(investorLeadStageUrl,{ cancelToken: source.token })
        return data
    } catch (error) {
        console.log(error)
        return []
    }
 
 }



export default {
    getInvestorLeads,
    getInvestorLead,
    getOptions,
    getStages,
    updateInvestorLead
}