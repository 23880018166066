import http from "./httpService";
// Import Endpoints
import {paymentProfile, card, paymentProfilev1, stripeConfirm,stripeIntents} from '../config/Endpoints';
import { fetchNextDataApi } from "../components/FetchNext";
import {Notify} from "../components/Notifications/Notify";


const getPayment=async(query="")=>{
    try {
        const {data}=await http.get(paymentProfile+query)
        const data2=await fetchNextDataApi(data.next)
        // console.log("pista",data.results,data2)
        return data

    } catch (error) {
        console.log(error)
        return
    }
}

const getPaymentv1=async(query="")=>{
    try {
        const {data}=await http.get(paymentProfilev1+query)
        // const data2=await fetchNextDataApi(data.next)
        // console.log("pista",data.results)
        return data

    } catch (error) {
        console.log(error)
        return
    }
}


const createPayment=async(payment)=>{
    try {
        const {data}= await http.post(`${paymentProfile}`,payment)
            // console.log(data,"waka")
            return data
        } catch (error) {
            // console.log(error,"waka")
            return error
    }
}


// todo fix this so that the calls on this page use "postData" so that it has proper error handling

const createCardTrans = async(payment) => {
    try {
        const { data } = await http.post(`${card}`, payment);
        // console.log(data, "waka");
        Notify('success', 'Transaction Successful!');
        return data;
    } catch (error) {
        Notify('error', 'Transaction failed: ' + JSON.stringify(error?.response?.data.message));
        console.error('error',error?.response?.data);
        throw new Error(error);
    }
}


const createBankTrans = async(payment,payment_method_id) => {
    console.log("createBankTrans",payment,payment_method_id)
    try {
        const { data } = await http.post(`${stripeIntents}`, payment);
        console.log(data, payment, "createBankTrans");

        const response = await http.put(stripeConfirm + data?.id + "/", {
            loan_id: payment.loan_id,
            payment_method_id: payment_method_id,
            // customer_id: data.stripe_id,
        });

        // console.log(response.data, "createBankConfirm");

        return data;
    } catch (error) {
        // console.error(error,"createBankTrans");
        throw new Error(error);
    }
}



export default {
    createPayment,
    getPayment,
    createCardTrans,
    createBankTrans,
    getPaymentv1
}
