import http from './httpService';
import { Notify } from '../components/Notifications/Notify';

// Import Endpoints
import { documentsTypeUrl, documentsUrl } from '../config/Endpoints';

const getDocumentsForContacts = async (id, name) => {
   try {
      const { data } = await http.get(`${documentsUrl}?${name}=${id}`);
      return data;
   } catch (error) {
      console.log(error);
   }
};

const getDocumentsForTrust = async id => {
   try {
      const { data } = await http.get(`${documentsUrl}?trust_ids=${id}`);
      return data;
   } catch (error) {
      console.log(error);
   }
};

const getDocumentsForLoan = async id => {
   try {
      const { data } = await http.get(`${documentsUrl}?loan_ids=${id}`);
      return data;
   } catch (error) {
      console.log(error);
   }
};

const getDocumentsForAll = async (id, query) => {
   try {
      const { data } = await http.get(`${documentsUrl}?${query}=${id}`);
      return data;
   } catch (error) {
      console.log(error);
      Notify("danger", "Documents not found")
   }
};
const getOptions = async (query) => {
   try {
      const { data } = await http.get(`${documentsTypeUrl}?${query}=true`);
      return data;
   } catch (error) {
      console.log(error);
   }
};

const deleteDocument = async id => {
   try {
      const { data } = await http.delete(`${documentsUrl}${id}/`, {
         headers: { 'Content-Type': 'multipart/form-data' },
      });
      Notify("success", "Document deleted")
      return data;
   } catch (error) {
         if (!!error?.response?.data?.message) {
            Object.entries(error.response.data.message).forEach(([field, messages]) => {
               if (Array.isArray(messages)) {
                  messages.forEach((message) => {
                     Notify("danger", `${field}: ${message}`);
                  });
               } else {
                  // If the value is not an array, you can directly show the message
                  Notify("danger", `${field}: ${messages}`);
               }
            });
         } else {
             Notify("danger", error.response.request.responseText);
         }
      }
};

const updateDocument = async (id, formData) => {
   console.log('id', id);
   try {
      const { data } = await http.patch(`${documentsUrl}${id}/`, formData, {
         headers: { 'Content-Type': 'multipart/form-data' },
      });
      Notify("success", "Document updated")

      return data;
   } catch (error) {
         if (!!error?.response?.data?.message) {
            Object.entries(error.response.data.message).forEach(([field, messages]) => {
               if (Array.isArray(messages)) {
                  messages.forEach((message) => {
                     Notify("danger", `${field}: ${message}`);
                  });
               } else {
                  // If the value is not an array, you can directly show the message
                  Notify("danger", `${field}: ${messages}`);
               }
            });
         } else {
             Notify("danger", error.response.request.responseText);
         }
      }
};

const uploadDocument = async formData => {
   try {
      const { data } = await http.post(`${documentsUrl}`, formData, {
         headers: { 'Content-Type': 'multipart/form-data' },
      });
      return data;
   } catch (error) {
         if (!!error?.response?.data?.message) {
            Object.entries(error.response.data.message).forEach(([field, messages]) => {
               if (Array.isArray(messages)) {
                  messages.forEach((message) => {
                     Notify("danger", `${field}: ${message}`);
                  });
               } else {
                  // If the value is not an array, you can directly show the message
                  Notify("danger", `${field}: ${messages}`);
               }
            });
         } else {
             Notify("danger", error.response.request.responseText);
         }
      }
};




export default {
   getOptions,
   updateDocument,
   uploadDocument,
   deleteDocument,
   getDocumentsForTrust,
   getDocumentsForContacts,
   getDocumentsForAll,
   getDocumentsForLoan,
};
