export const BOX_LAYOUT = 'BOX_LAYOUT';
export const CHANGE_LAYOUT = 'CHANGE_LAYOUT';
export const CHANGE_SUB_LAYOUT = 'CHANGE_SUB_LAYOUT';
export const COLLAPSE_MENU = 'COLLAPSE_MENU';
export const COLLAPSE_TOGGLE = 'COLLAPSE_TOGGLE';
export const CONFIG_BLOCK = 'CONFIG_BLOCK';
export const HEADER_BACK_COLOR = 'HEADER_BACK_COLOR';
export const HEADER_FIXED_LAYOUT = 'HEADER_FIXED_LAYOUT';
export const LAYOUT_TYPE = 'LAYOUT_TYPE';
export const LAYOUT6_BACKGROUND = 'LAYOUT6_BACKGROUND';
export const NAV_ACTIVE_LIST_COLOR = 'NAV_ACTIVE_LIST_COLOR';
export const NAV_BRAND_COLOR = 'NAV_BRAND_COLOR';
export const NAV_BACK_COLOR = 'NAV_BACK_COLOR';
export const NAV_BACK_IMAGE = 'NAV_BACK_IMAGE';
export const NAV_DROPDOWN_ICON = 'NAV_DROPDOWN_ICON';
export const NAV_COLLAPSE_LEAVE = 'NAV_COLLAPSE_LEAVE';
export const NAV_CONTENT_LEAVE = 'NAV_CONTENT_LEAVE';
export const NAV_FIXED_LAYOUT = 'NAV_FIXED_LAYOUT';
export const NAV_ICON_COLOR = 'NAV_ICON_COLOR';
export const NAV_LIST_ICON = 'NAV_LIST_ICON';
export const NAV_LIST_TITLE_COLOR = 'NAV_LIST_TITLE_COLOR';
export const NAV_LIST_TITLE_HIDE = 'NAV_LIST_TITLE_HIDE';
export const RESET = 'RESET';
export const RTL_LAYOUT = 'RTL_LAYOUT';

export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const ACCOUNT_INITIALIZE = 'ACCOUNT_INITIALIZE';

export const GET_TRUST_LIST = 'GET_TRUST_LIST';
export const ADD_TRUST_LIST = 'ADD_TRUST_LIST';
export const UPDATE_TRUST_LIST='UPDATE_TRUST_LIST';
export const GET_TRUST = 'GET_TRUST';
export const EDIT_TRUST = 'EDIT_TRUST';
export const GET_TRUST_ADDRESS="GET_TRUST_ADDRESS"
export const GET_TRUST_OPTIONS = 'GET_TRUST_OPTIONS';

export const GET_SITUATIONS_LIST = 'GET_SITUATIONS_LIST';
export const ADD_SITUATIONS_LIST = 'ADD_SITUATIONS_LIST';
export const UPDATE_SITUATIONS_LIST='UPDATE_SITUATIONS_LIST';
export const GET_SITUATIONS = 'GET_SITUATIONS';
export const EDIT_SITUATIONS = 'EDIT_SITUATIONS';

export const GET_CONTACT_LIST = 'GET_CONTACT_LIST';
export const ADD_CONTACT_LIST = 'ADD_CONTACT_LIST';
export const GET_CONTACT = 'GET_CONTACT';
export const EDIT_CONTACT = 'EDIT_CONTACT';
export const GET_CONTACT_OPTIONS = 'GET_CONTACT_OPTIONS';
export const UPDATE_CONTACT_LIST='UPDATE_CONTACT_LIST';
export const GET_AMBASSADOR='GET_AMBASSADOR';


export const GET_ADDRESS_LIST = 'GET_ADDRESS_LIST';
export const ADD_ADDRESS_LIST = 'ADD_ADDRESS_LIST';
export const GET_ADDRESS = 'GET_ADDRESS';
export const EDIT_ADDRESS = 'EDIT_ADDRESS';
export const GET_ADDRESS_OPTIONS = 'GET_ADDRESS_OPTIONS';
export const UPDATE_ADDRESS_LIST='UPDATE_ADDRESS_LIST';

export const GET_CHATTER = 'GET_CHATTER';

export const GET_TRUST_OWNERSHIP = 'GET_TRUST_OWNERSHIP';
export const GET_FAMILY_HISTORY = 'GET_FAMILY_HISTORY';
export const GET_MARKETING_HISTORY = 'GET_MARKETING_HISTORY';
export const GET_TAXES = 'GET_TAXES';
export const GET_INSURANCE = 'GET_INSURANCE';

export const GET_INVESTOR_LIST = 'GET_INVESTOR_LIST';
export const ADD_INVESTOR_LIST = 'ADD_INVESTOR_LIST';
export const UPDATE_INVESTOR_LIST='UPDATE_INVESTOR_LIST';
export const GET_INVESTOR = 'GET_INVESTOR';
export const GET_INVESTOR_OPTIONS = 'GET_INVESTOR_OPTIONS';
export const GET_INVESTOR_ADDRESS="GET_TRUST_ADDRESS"


export const GET_FAMILYLEAD_LIST = 'GET_FAMILYLEAD_LIST';
export const ADD_FAMILYLEAD_LIST="ADD_FAMILYLEAD_LIST";
export const UPDATE_FAMILYLEAD_LIST='UPDATE_FAMILYLEAD_LIST';
export const GET_FAMILYLEAD = 'GET_FAMILYLEAD';
export const GET_FAMILYLEAD_OPTIONS = 'GET_FAMILYLEAD_OPTIONS';

export const GET_INVESTORLEAD_LIST = 'GET_INVESTORLEAD_LIST';
export const ADD_INVESTORLEAD_LIST='ADD_INVESTORLEAD_LIST';
export const UPDATE_INVESTORLEAD_LIST='UPDATE_INVESTORLEAD_LIST';
export const GET_INVESTORLEAD = 'GET_INVESTORLEAD';
export const GET_INVESTORLEAD_OPTIONS = 'GET_INVESTORLEAD_OPTIONS';

export const GET_FAMILY_LIST = 'GET_FAMILY_LIST';
export const ADD_FAMILY_LIST = 'ADD_FAMILY_LIST';
export const UPDATE_FAMILY_LIST='UPDATE_FAMILY_LIST';
export const GET_FAMILY = 'GET_FAMILY';
export const GET_FAMILY_OPTIONS = 'GET_FAMILY_OPTIONS';

export const INVESTOR_PORTAL_DATA = 'INVESTOR_PORTAL_DATA';