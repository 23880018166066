import {
    GET_AMBASSADOR,
} from './actions';

export const initialState = {
    ambassadorList:{}
};

const ambassadorReducer = (state = initialState, action) => {
switch (action.type) {
    case GET_AMBASSADOR:
        return {
            ...state,
            ambassadorList: action.payload
        };

    // case ADD_FAMILY_LIST:
    //     return {
    //         ...state,
    //         familyList: {...state.familyList,...action.payload}
    //     };
    // case UPDATE_FAMILY_LIST:
    //         return {
    //             ...state,
    //             familyList:{
    //                 ...state.familyList,
    //                 ...action.payload}
    //         };
    default:
        return state;
}
}

export default ambassadorReducer;