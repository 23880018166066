import React from 'react'
import { Player, Controls } from '@lottiefiles/react-lottie-player';
export default function LoadingHand({height="400px",width="400px"}) {
  return (
      <div>
<Player
  autoplay
  mode="scroll"
  loop
  src="https://assets2.lottiefiles.com/private_files/lf30_p5tali1o.json"
  className="mb-n5"
    style={{ height, width,marginBottom:'-20em'  }}
  // style={{ height, width,filter: "invert(0.5) sepia(1) saturate(5) hue-rotate(175deg)" }}
/>
<div  style={{ marginTop:'-18em' }}>
      <Player
  autoplay
  mode="scroll"
  loop
  src="https://assets3.lottiefiles.com/private_files/lf30_w5jfiiaa.json"
    style={{ height, width,marginTop:'-2em'}}
  // style={{ height, width,filter: "invert(0.5) sepia(1) saturate(5) hue-rotate(175deg)" }}
/>
</div>
  </div>
  )
}
