import http from "./httpService";
import {floatFormatterForFamilyLead, floatFormatterForInvestor} from '../components/Custom/floatFormatter.js'
import {fieldTypeContact} from "../config/contact/fieldTypeContact.js"
// Import Endpoints
import { familyUrl,familyShort } from '../config/Endpoints';


const apiEndpoint=`${familyUrl}`
const apiEndpointShort=`${familyShort}`

const getFamilies=async(query="")=>{
   try {
       const {data}=await http.get(apiEndpoint+query)
       const parsedList = floatFormatterForInvestor(data.results, fieldTypeContact)
       return {...data,results:parsedList}
   } catch (error) {
       console.log(error)
       return
   }
}

const getFamily=async(id)=>{
    if(!id) return {}
    try {
        const {data}=await http.get(`${apiEndpoint}${id}`)
        const parsedList = floatFormatterForInvestor(data, fieldTypeContact)
        return parsedList
    } catch (error) {
        console.log(error)
        return {}
    }
}

 const updateFamily=async(id,family)=>{
    try {
       const {data}= await http.patch(`${apiEndpoint}${id}/`,family)
       const parsedList = floatFormatterForFamilyLead(data, fieldTypeContact)
       return parsedList
    } catch (error) {
        console.log(error)
        return
    }
}


const getFamilyShorts=async(query="")=>{
    try {
        const {data}=await http.get(`${apiEndpointShort}`)
        return data
      } catch (error) {
          console.log(error)
      }
 }



const getOptions = async() => {
    try {
        const {data}=await  http.options(`${apiEndpoint}`)
        console.log(data,'optiond')
        return data.actions.POST.contact.children
      } catch (error) {
          console.log(error)
      }
}


export default {
    getFamilies,
    getFamily,
    getOptions,
    updateFamily,
    getFamilyShorts
}