import http from "./httpService";
// Import Endpoints
import {
    propertiesUrl,
    propertiesUrlv1,
    propertiesSub,
    rerunScrape,
    propertiesUrlDetail,
    acqImportUrl,
    massAcqDel,
    acqCreateScrape,
    scrapeDelete,
    rtpPDF,
    createTrustAcq,
    acqStageUrlV1, acqSubStageUrlV1
} from '../config/Endpoints';

import { fetchNextDataApi } from "../components/FetchNext";
import httpService from "./httpService";

const source=httpService.getSource()

const getProperties=async(query="")=>{
    try {
        const {data}=await http.get(propertiesUrlv1+query)
        //    const parsedList = floatFormatterForInvestor(data.results, fieldTypeContact)
        //    console.log("xyzx",parsedList)
        const data2=await fetchNextDataApi(data.next)
        // console.log("pista",data.results,data2)
        return data

    } catch (error) {
        console.log(error)
        return
    }
}

const createAcqImport=async(trust)=>{
    try {
        const {data}= await http.post(`${acqImportUrl}`, trust)
        // console.log('create trust',data)
        return data
    } catch (error) {
        console.log(error)
        return {}
    }
}

const getPropertiesSub=async()=>{
    //todo get this working
    try {
        const {data}=await http.get(acqSubStageUrlV1+"?ordering=stage",{ cancelToken: source.token })
        console.log('substaged:',data.results)
        return data.results
    } catch (error) {
        console.log({error})
        return []
    }
}

const getPropertiesStage=async()=>{
    try {
        const {data}=await http.get(acqStageUrlV1+"?ordering=stage",{ cancelToken: source.token })
        return data.results
    } catch (error) {
        console.log(error)
        return []
    }

}


const createScrape=async(scrape)=>{
    try {
        const {data}= await http.post(`${acqCreateScrape}`,scrape)
        return data
    } catch (error) {
        console.log(error)
        return
    }
}

const createTrust=async(scrape)=>{
    try {
        const {data}= await http.post(`${createTrustAcq}`,scrape)
        return data
    } catch (error) {
        console.log(error)
        return
    }
}


const runScrape=async(id)=>{
    try {
        const {data}= await http.post(`${rerunScrape}`,id)
        return data
    } catch (error) {
        console.log(error)
        return
    }
}


const getScrapes=async()=>{
    try {
        const {data}= await http.get(`${acqCreateScrape}`)
        return data
    } catch (error) {
        console.log(error)
        return
    }
}


// const getAmbassadorss=async(query="")=>{
//     try {
//         const {data}=await http.get(propertiesUrl+query)
//         // const parsedList = floatFormatterForInvestor(data.results, fieldTypeContact)
//         // console.log("xyzx",parsedList)
//         return data

//     } catch (error) {
//         console.log(error)
//         return
//     }
//  }

const getProperty=async(id)=>{
    if(!id) return {}
    try {
        const {data}=await http.get(`${propertiesUrlDetail}${id}`)
        // const parsedList = floatFormatterForInvestor(data, fieldTypeContact)
        return data
    } catch (error) {
        console.log(error)
        return {}
    }
}

const getScrape=async(id)=>{
    if(!id) return {}
    try {
        const {data}=await http.get(`${acqCreateScrape}${id}`)
        // const parsedList = floatFormatterForInvestor(data, fieldTypeContact)
        return data
    } catch (error) {
        console.log(error)
        return {}
    }
}



const updateProperty=async(id,property)=>{
    try {
        const {data}= await http.patch(`${propertiesUrlv1}${id}/`,property)
        return data
    } catch (error) {
        console.log(error)
        return
    }
}

const updateScrape=async(id,property)=>{
    try {
        const {data}= await http.put(`${acqCreateScrape}${id}/`,property)
        return data
    } catch (error) {
        console.log(error)
        return
    }
}


const getDataPDF=async(arr)=>{
    try {
        const {data}= await http.post(`${rtpPDF}`,{ids:arr})
        return data
    } catch (error) {
        console.log(error)
        return
    }
}

const createProperty=async(property)=>{
    try {
        const {data}= await http.post(`${propertiesUrl}`,property)
        return data
    } catch (error) {
        console.log("chup",error)
        return error
    }
}

const deleteProperty=async(property)=>{
    try {
        const {data}= await http.post(`${massAcqDel}`,property)
        return data
    } catch (error) {
        console.log(error)
        return
    }
}

const deleteScrape=async(property)=>{
    try {
        const {data}= await http.post(`${scrapeDelete}`,property)
        return data
    } catch (error) {
        console.log(error)
        return
    }
}





const getOptions=async()=>{
    try {
        const {data}= await http.options(`${propertiesUrlv1}`)
        return data
    } catch (error) {
        console.log(error)
        return
    }
}



const getOptionsStage=async()=>{
    try {
        const {data}= await http.options(`${acqCreateScrape}`)
        return data
    } catch (error) {
        console.log(error)
        return
    }
}





export default {
    getProperties,
    getOptions,
    getProperty,
    getDataPDF,
    getPropertiesSub,
    getPropertiesStage,
    updateProperty,
    createAcqImport,
    createProperty,
    deleteProperty,
    createScrape,
    getScrapes,
    getOptionsStage,
    updateScrape,
    deleteScrape,
    getScrape,
    runScrape,
    createTrust
}