import http from "./httpService";

// Import Endpoints
import {addressUrlV1, addressDDUrlV1} from '../config/Endpoints';

const apiEndpoint=addressUrlV1

const getAddresses=async(source)=>{
    try {

        const {data}=await http.get(apiEndpoint,{ cancelToken: source?.token })
        // console.log("called1",data)

        return data
    } catch (error) {
        console.log(error)
        return
    }
}


const getAddressesv1=async(source)=>{
    try {
        const {data}=await http.get(addressDDUrlV1,{ cancelToken: source?.token })
        // console.log("called",data)
        return data
    } catch (error) {
        console.log(error)
        return
    }
}

const getAddressesShort=async(source)=>{
    try {

        const {data}=await http.get(addressDDUrlV1,{ cancelToken: source?.token })
        // console.log("called1",data)

        return data
    } catch (error) {
        console.log(error)
        return
    }
}




const getAddress=async(id)=>{
    if(!id) return {}
    try {
        const {data}=await http.get(`${apiEndpoint}${id}`)
        // const parsedList = floatFormatterForInvestor(data, fieldTypeContact)
        return data
    } catch (error) {
        console.log(error)
        return {}
    }
}


const updateAddress=async(id,body,source)=>{
    try {
        const {data}=await http.put(`${apiEndpoint}${id}/`,body,{ cancelToken: source?.token })
        return data
    } catch (error) {
        console.log(error)
        return
    }
}


const getOptions = async(source) => {
    try {
        const {data}=await  http.options(`${apiEndpoint}`,{ cancelToken: source.token })
        return data
      } catch (error) {
          console.log(error)
      }
}

const createAddress = async(address,source) => {
    try {
      const {data}=await  http.post(`${apiEndpoint}`, address,{ cancelToken: source?.token })
       return data
    } catch (error) {
        console.log(error)
    }
    }

export default {
    getAddresses,
    getAddress,
    updateAddress,
    getOptions,
    createAddress,
    getAddressesv1
}