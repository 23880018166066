export const fieldTypeContact = {
    "id": "key",
    "investor_price": "monetary",
    "warranty": "monetary",
    "pi_approved_price": "monetary",
    "eh_list_price": "monetary",
    "current_tax_balance": "monetary",
    "current_monthly_tax": "monetary",
    "current_annual_tax": "monetary",
    "current_insurance_balance": "monetary",
    "rate_type": "choice",
    "current_monthly_insurance": "monetary",
    "current_bills_balance": "monetary",
    "on_market": "boolean",
    "owner": "string",
    "investor_purchase_date": "date",
    "trustee": "string",
    "photos": "media",
    "name": "string",
    "lockbox": "string",
    "purchase_date": "date",
    "trust_create_date": "date",
    "deed_record_date": "date",
    "insurance_company": "date",
    "insured_amount": "monetary",
    "assigned_vendor": "key",
    "eh_purchase_price": "monetary",
    "daily_insurance_rate": "monetary",
    "policy_number": "string",
    "prop_pres_notes": "string",
    "easy_homes_notes": "string",
    "trustee_type": "choice",
    "arv": "monetary",
    "property_source": "string",
    "family_id": "key",
    "sale_manager_id": "key",
    "loan_amount": "monetary",
    "land_contract_name_id": "key",
    "insurance_rate_choice": "choice",
    "address_id": "key",
    "occupied_stage_id": "key",
    "set_up_stage_id": "key",
    "further_repair_stage_id": "key"
}