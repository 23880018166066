import React, {useEffect, useState} from 'react';
import {Row, Form, Col, Button, Modal} from 'react-bootstrap';

import Cards from 'react-credit-cards-2';
import 'react-credit-cards-2/dist/es/styles-compiled.css';
import { postData } from '../../../services/endpointService';
import { authnetUrl } from '../../../config/Endpoints';
import { Notify } from '../../Notifications/Notify';
import Datetime from "react-datetime";
import moment from "moment";



const CreateCard = ({ createCustomer, loanState, reloadAccounts,setPaymentMethod }) => {
    const [cardState, setCardState] = useState({
        card_number: '',
        card_expiration: '',
        card_cvv: '',
        card_first_name: '',
        card_last_name: '',
        card_zipcode: '',
        default_profile: false,
        create_customer_profile: createCustomer(),
        contact_id: loanState.contact_id.id
    });

    const [focused, setFocused] = useState("");

    const handleChecked = (event) => {
        setCardState({
            ...cardState,
            default_profile: event.target.value
        })
    }

    const handleFocus = (e) => {
        setFocused(e.target.id)
    }

    const handleTextChange = (e) => {
        setCardState({
            ...cardState,
            [e.target.name]: e.target.value
        })
    }

    const handleVerify = () => {
        // console.log(cardState)
        postData(authnetUrl, cardState)
           .then((res) => {
               if (res.status === 201) {
                   console.log(res)
                   reloadAccounts()
                   Notify('success', "Card has been added")
                   setPaymentMethod({id: res.data.id, label: res.data.card_last_four})
                   return
               }
               Notify('danger', "Error saving CC")
           })
    }

    return (
       <Modal show={true}>
           <Modal.Body>

               <Row  style={{paddingTop: 15, }}>
                   <Cards
                      number={cardState.card_number}
                      expiry={cardState.card_expiration}
                      cvc={cardState.card_cvv}
                      name={`${cardState.card_first_name} ${cardState.card_last_name}`}
                      focused={focused}
                      // callback={handleCallback}
                   />
               </Row>

               <Form style={{paddingTop: 15}}>

                   <Form.Group as={Row}>
                       <Form.Label column xs={2}>
                           Card Number
                       </Form.Label>
                       <Col xs={10}>
                           <Form.Control
                              name="card_number"
                              id='number'
                              type="text"
                              required={true}
                              onChange={handleTextChange}
                              onFocus={handleFocus}
                              pattern="[\d| ]{16,22}"
                           />
                       </Col>
                   </Form.Group>

                   <Row>
                       <Col md={6}>
                           <Form.Group as={Row}>
                               <Form.Label column sm={4}>
                                   First Name
                               </Form.Label>
                               <Col sm={8}>
                                   <Form.Control
                                      name="card_first_name"
                                      id='name'
                                      required
                                      type="text"
                                      onChange={handleTextChange}
                                      onFocus={handleFocus}
                                   />
                               </Col>
                           </Form.Group>
                       </Col>
                       <Col md={6}>
                           <Form.Group as={Row}>
                               <Form.Label column sm={4}>
                                   Last Name
                               </Form.Label>
                               <Col sm={8}>
                                   <Form.Control
                                      name="card_last_name"
                                      id='name'
                                      required
                                      type="text"
                                      onChange={handleTextChange}
                                      onFocus={handleFocus}
                                   />
                               </Col>
                           </Form.Group>
                       </Col>
                   </Row>

                   <Row>
                       <Col md={6}>
                           <Form.Group as={Row}>
                               <Form.Label column sm={4}>
                                   Exp
                               </Form.Label>
                               <Col sm={8}>
                                   <Datetime
                                      dateFormat="MM-YYYY"
                                      timeFormat={false}
                                      inputProps={{
                                          name: "card_expiration",
                                          id: 'expiry',
                                          required: true,
                                          onFocus: handleFocus
                                      }}
                                      onChange={(date) => handleTextChange({
                                          target: {
                                              name: "card_expiration",
                                              value: moment(date).format("MM-YYYY")
                                          }
                                      })}
                                   />
                               </Col>
                           </Form.Group>
                       </Col>
                       <Col md={6}>
                           <Form.Group as={Row}>
                               <Form.Label column sm={4}>
                                   CVC
                               </Form.Label>
                               <Col sm={8}>
                                   <Form.Control
                                      name="card_cvv"
                                      id='cvc'
                                      pattern="\d{3,4}"
                                      required
                                      type="tel"
                                      onChange={handleTextChange}
                                      onFocus={handleFocus}
                                   />
                               </Col>
                           </Form.Group>
                       </Col>
                   </Row>

                   <Row>
                       <Col md={6}>
                           <Form.Group as={Row}>
                               <Form.Label column sm={4}>
                                   Zipcode
                               </Form.Label>
                               <Col sm={8}>
                                   <Form.Control
                                      name="card_zipcode"
                                      maxLength="5"
                                      required
                                      type="tel"
                                      onChange={handleTextChange}
                                      onFocus={handleFocus}
                                   />
                               </Col>
                           </Form.Group>
                       </Col>
                       <Col md={6}>
                           <Form.Group as={Row}>
                               <Form.Label column sm={4}>
                                   Default Card
                               </Form.Label>
                               <Col sm={8}>
                                   <Form.Check
                                      style={{marginTop: 5}}
                                      name="defaultCard"
                                      id="defaultCard"
                                      type="checkbox"
                                      onChange={handleChecked}
                                   />
                               </Col>
                           </Form.Group>
                       </Col>
                   </Row>
                   <div>
                       <Button
                          className="btn-sm shadow-1 text-white theme-bg border border-0 align-end mt-3 flex float-right"
                          onClick={handleVerify}
                       >
                           Add Card
                       </Button>
                   </div>
                   <div>
                       <Button
                          className="btn-sm btn-danger text-white shadow-1 border-0 align-end mt-3 flex float-right"
                          onClick={() => setPaymentMethod({id: "select", label: "Select..."})}
                       >
                           Close
                       </Button>
                   </div>


               </Form>
           </Modal.Body>
       </Modal>
    )
}

export default CreateCard;