import React, { useContext, useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
// import { Redirect } from 'react-router-dom';
import './index.css';
import routes, { renderRoutes } from './routes';
import { BASENAME } from './config/constant';
import "ag-grid-enterprise";
import { LicenseManager } from  'ag-grid-enterprise'
import './assets/scss/partials/agGrid.scss';
import { ConfigContext } from './contexts/ConfigContext';
// import { ErrorBoundary } from 'react-error-boundary';

LicenseManager.setLicenseKey("Using_this_AG_Grid_Enterprise_key_( AG-048786 )_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_( legal@ag-grid.com )___For_help_with_changing_this_key_please_contact_( info@ag-grid.com )___( Humanagement LLC )_is_granted_a_( Multiple Applications )_Developer_License_for_( 1 ))_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_need_to_be_licensed_in_addition_to_the_ones_working_with_AG_Grid_Enterprise___This_key_has_not_been_granted_a_Deployment_License_Add-on___This_key_works_with_AG_Grid_Enterprise_versions_released_before_( 4 October 2024 )____[v2]_MTcyNzk5NjQwMDAwMA==e206a710510aa81fbe6a72064432cc87")

const App = () => {
   const configContext = useContext(ConfigContext);
   const { layoutType } = configContext.state;
   useEffect(() => {
      const root = document.documentElement;
      root?.style.setProperty(
         '--b-color',
         layoutType === 'menu-dark' ? 'var(--ag-light)' : 'var(--ag-dark)'
      );
      root?.style.setProperty(
         '--odd-color',
         layoutType === 'menu-dark'
            ? 'var(--odd-row-dark)'
            : 'var(--odd-row-light)'
      );
      root?.style.setProperty(
         '--border-color',
         layoutType === 'menu-dark'
            ? 'var(--border-color-light)'
            : 'var(--border-color-dark)'
      );
      root?.style.setProperty(
         '--text-color',
         layoutType === 'menu-dark'
            ? 'var(--text-light-color)'
            : 'var(--text-dark-color)'
      );
   }, [layoutType]);

   // const ErrorFallback = ({ error, resetErrorBoundary }) => {
   //    return (
   //       <React.Fragment>
   //          <h3 style={{ textAlign: 'center', marginTop: '2em' }}>
   //             OPPS Error! Please Refresh
   //          </h3>
   //          {/* for automatic reloading when errors uncomment the code below and 
   //    comment the button tag */}
   //          {/* <pre>{error.message && window.location.reload(false)}</pre> */}
   //          <p style={{ textAlign: 'center' }}>
   //             <button
   //                style={{ marginTop: '1em', width: '30%' }}
   //                class="btn btn-danger"
   //                onClick={() => window.location.reload(false)}
   //             >
   //                Click Here To Refresh
   //             </button>
   //          </p>
   //          {console.log('ad', error?.message)}
   //       </React.Fragment>
   //    );
   // };

   return (
      // <ErrorBoundary FallbackComponent={ErrorFallback}>
      <React.Fragment>
         <Router basename={BASENAME}>{renderRoutes(routes)}</Router>
      </React.Fragment>
      // </ErrorBoundary>
   );
};

export default App;
