import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Select from 'react-select';
import { Card, Form, Button, Row, Col } from 'react-bootstrap';
import { userRequestUrl, usersUrlV1 } from '../../config/Endpoints';
import {
    getFilteredData,
    patchData,
    postData,
    getData,
    getManyData
} from '../../services/endpointService';
import LoadingHand from "../../components/Loader/LoadingHand";

const UserRequestForm = () => {
    const history = useHistory();
    const user = useSelector(state => state.account.user?.user);

    const [isLoading, setIsLoading] = useState(true);
    const [editMode, setEditMode] = useState(false);
    const [formData, setFormData] = useState(false);
    const [submitData, setSubmitData] = useState(false);
    const [selectOptions, setSelectOptions] = useState({
        assignedToOptions: [],
        originatorOptions: [],
        requestTypeOptions: [],
        stageOptions: []
    });

    const [location, setLocation] = useState('misc');

    const [selected, setSelected] = useState({
        assignedTo: [],
        originator: [],
        requestType: [],
        stage: []
    });

    useEffect(() => {
        if (history.location.pathname.includes('famportal') || history.location.pathname.includes('family')) {
            setLocation('family')
        } else if (history.location.pathname.includes('investor')) {
            setLocation('investor')
        }
        if (history.location.pathname.includes('create')) {
            setEditMode(true)
        }
    }, [history]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const employeesResponse = await getFilteredData(usersUrlV1, 'is_employee', 'true');
                let users = []
                if (location === 'investor') {
                    users = await getFilteredData(usersUrlV1, 'is_investor', true)
                } else if (location === 'family') {
                    users = await getFilteredData(usersUrlV1, 'is_family', true)
                } else {
                    if (user.is_employee) {
                        users = await getManyData(usersUrlV1)
                    } else if (user.is_family || user.is_investor) {
                        users = [{id: user.id, label: user.name}]
                    }
                }

                // console.log({investorsResponse,employeesResponse})
                setSelectOptions({
                    assignedToOptions: employeesResponse[0].map(e => ({ id: e.id, name: `${e.first_name} ${e.last_name}` })),
                    originatorOptions: users.map(i => ({ id: i.id, name: i.full_name })),
                    requestTypeOptions: [
                        { id: 'investor', name: 'Investor' },
                        { id: 'family', name: 'Family' },
                        { id: 'misc', name: 'Other' }
                    ],
                    stageOptions: [
                        { id: 'submitted', name: 'Submitted' },
                        { id: 'pending', name: 'Pending' },
                        { id: 'resolved', name: 'Resolved' }
                    ]
                })


            } catch (error) {
                console.log("employeesResponse",error)
            } finally {
                setIsLoading(false);
            }
        };
        fetchData();
    }, []);


    useEffect(() => {
        // console.log("originator_id",formData?.ticket_base?.originator?.id,formData.assigned_to?.id)

        let baseSelected = {
            assignedTo: selectOptions.assignedToOptions.find(item => item.id === formData?.assigned_to?.id) || null,
            originator: selectOptions.originatorOptions.find(item => item.id === formData?.ticket_base?.originator.id) || null,
            stage: selectOptions.stageOptions.find(item => item.label === formData?.stage) || selectOptions.stageOptions[0]

        }

        if (location === 'family') {
            baseSelected = {
                ...baseSelected,
                requestType: { id: 'family', name: 'Family' }
            }
        } else if (location === 'investor') {
            baseSelected = {
                ...baseSelected,
                requestType: { id: 'investor', name: 'Investor' }
            }
        } else {
            baseSelected = {
                ...baseSelected,
                requestType: selectOptions.requestTypeOptions.find(item => item.label === formData?.request_type) || selectOptions.requestTypeOptions[0]
            }
        }

        setSelected(baseSelected)


    },[selectOptions])

    // useEffect(() => {
    //     console.log(user)
    // }, [user]);

    useEffect(() => {
        const id = getIdFromPath();
        if (id !== 'create') {
            setIsLoading(true);
            getData(userRequestUrl, id)
               .then(res => {
                   setFormData(res.data);
                   setIsLoading(false);
               })
               .catch(error => {
                   // Handle error
                   setIsLoading(false);
               });
        }
    }, []);

    const handleChange = (event) => {
        const { name, value } = event.target
        if (name === 'subject'||name === 'description'||name === 'proposed_solution') {
            setFormData(prev => ({ ...prev, ticket_base: {...prev.ticket_base, [name] :value} }));
            setSubmitData(prev => ({ ...prev, ticket_base: {...prev.ticket_base,[name] :value } }));
        }
        else {
            setFormData(prev => ({ ...prev, [name]: value }));
            setSubmitData(prev => ({ ...prev, [name]: value }));
        }
        // console.log({name, value})
    };

    const handleSelect = (selectedOption, options) => {
        if (options.name === 'originator') {
            setFormData(prev => ({ ...prev, ticket_base: {...prev.ticket_base, [options.name] :selectedOption.id} }));
            setSubmitData(prev => ({ ...prev, ticket_base: {...prev.ticket_base,[options.name] :selectedOption.id } }))}
        else {
            setFormData(prev => ({ ...prev, [options.name]: selectedOption.id }));
            setSubmitData(prev => ({ ...prev, [options.name]: selectedOption.id }));
        }
    };

    // console.log({submitData},formData,selected)

    const handleSave = () => {
        const id = getIdFromPath();
        if (id === 'create') {
            postData(userRequestUrl, submitData).then(handleRedirect).catch(console.error)
               .then(()=>{
                   setEditMode(false)
                   setSubmitData(false)
               })
        } else {
            patchData(userRequestUrl, id, submitData).then(handleRedirect).catch(console.error)
               .then(()=>{
                   setEditMode(false)
                   setSubmitData(false)
               })
        }

    };

    const getIdFromPath = () => {
        const locationSplit = history.location.pathname.split("/");
        // console.log(locationSplit[locationSplit.length - 1])
        return locationSplit[locationSplit.length - 1];
    };

    function handleRedirect(res) {
        const newId = res.data.id
        if (user.is_investor) {
            history.replace(`/investor-portal/requests/${newId}`)
        } else if (user.is_family) {
            history.replace(`/famportal/requests/${newId}`)
        } else {
            history.replace(`/user-requests/${newId}`)
        }
    }
    if (isLoading) {
        return <LoadingHand />;
    }

    const id = getIdFromPath()

    return (
       <Card>
           <Card.Header>
               <Card.Title>
                   <Row>
                       <Col>Request Form</Col>
                       <Col sm={3}>
                           {
                               editMode
                                  ?
                                  <div className='float-right'>
                                      <Button
                                         className='btn-sm btn-danger'
                                         onClick={() => setEditMode(prevState => !prevState)}
                                      >
                                          Cancel
                                      </Button>
                                      <Button
                                         className='btn-sm mr-0'
                                         onClick={handleSave}
                                      >
                                          Send
                                      </Button>
                                  </div>
                                  : <div>
                                      <Form.Group>
                                          <Form.Switch
                                             className='float-right'

                                             id='editSwitch'
                                             label='Edit'
                                             checked={editMode}
                                             onChange={() => setEditMode(prevState => !prevState)}
                                          />
                                      </Form.Group>
                                  </div>
                           }
                       </Col>
                   </Row>
               </Card.Title>
           </Card.Header>
           <Card.Body>
               <Form>
                   <Row>
                       <Col md={6}>
                           <Form.Group as={Row}>
                               <Form.Label column sm={4}>
                                   {
                                       location === 'family'
                                          ? 'Family Name'
                                          : (
                                             location === 'investor'
                                                ? 'Investor Name'
                                                : 'Contact Name'
                                          )
                                   }
                               </Form.Label>
                               <Col sm={8}>
                                   {
                                       user.is_employee && editMode
                                          ?
                                          <Select
                                             isSearchable
                                             name="originator"
                                             defaultValue={selected.originator}
                                             getOptionValue={option => option.id}
                                             getOptionLabel={option => option.name}
                                             onChange={handleSelect}
                                             className="originator"
                                             placeholder="Select"
                                             options={selectOptions?.originatorOptions}
                                          />
                                          :
                                          <Form.Control
                                             readOnly={true}
                                             plaintext={true}
                                             name="originator"
                                             value={user?.name || ''}
                                          />
                                   }
                               </Col>
                           </Form.Group>
                           <Form.Group as={Row}>
                               <Form.Label column sm={4}>
                                   Description
                               </Form.Label>
                               <Col sm={8}>
                                   <Form.Control
                                      as='textarea'
                                      readOnly={!editMode}
                                      plaintext={!editMode}
                                      name="description"
                                      value={formData?.ticket_base?.description || ''}
                                      onChange={handleChange}
                                   />
                               </Col>
                           </Form.Group>
                           {  id !== 'create' &&
                              <Form.Group as={Row}>
                                  <Form.Label column sm={4}>
                                      Status
                                  </Form.Label>

                                  <Col sm={8}>
                                      <Form.Control
                                         readOnly={!editMode && user.is_employee}
                                         plaintext={!editMode && user.is_employee}
                                         name="status"
                                         onChange={handleChange}
                                         value={formData?.status || ''}
                                      />
                                  </Col>


                              </Form.Group>
                           }
                       </Col>
                       <Col md={6}>
                           <Form.Group as={Row}>
                               <Form.Label column sm={4}>
                                   Subject
                               </Form.Label>
                               <Col sm={8}>
                                   <Form.Control
                                      readOnly={!editMode}
                                      plaintext={!editMode}
                                      name="subject"
                                      value={formData?.ticket_base?.subject || ''}
                                      onChange={handleChange}
                                   />
                               </Col>
                           </Form.Group>
                           <Form.Group as={Row}>
                               <Form.Label column sm={4}>
                                   Solution
                               </Form.Label>
                               <Col sm={8}>
                                   <Form.Control
                                      as='textarea'
                                      readOnly={!editMode}
                                      plaintext={!editMode}
                                      name="proposed_solution"
                                      value={formData?.ticket_base?.proposed_solution || ''}
                                      onChange={handleChange}
                                   />
                               </Col>
                           </Form.Group>
                           {  id !== 'create' &&
                              <Form.Group as={Row}>
                                  <Form.Label column sm={4}>
                                      Stage
                                  </Form.Label>
                                  <Col sm={8}>
                                      {
                                          user.is_employee && editMode
                                             ? <Select
                                                isSearchable
                                                name="stage"
                                                defaultValue={selected.stage}
                                                getOptionValue={option => option.id}
                                                getOptionLabel={option => option.name}
                                                onChange={handleSelect}
                                                className="stage"
                                                placeholder="Select"
                                                options={selectOptions?.stageOptions}

                                             />
                                             :
                                             <Form.Control
                                                readOnly={!editMode}
                                                plaintext={!editMode}
                                                name="stage"
                                                value={formData?.stage}
                                             />
                                      }
                                  </Col>
                              </Form.Group>
                           }
                       </Col>
                   </Row>
                   <Row>
                       <Col>
                           {
                              user.is_employee &&
                              <Form.Group as={Row}>
                                  <Form.Label column sm={2}>
                                      Assigned Employee
                                  </Form.Label>
                                  <Col sm={10}>
                                      {
                                          editMode
                                             ? <Select
                                                isSearchable
                                                name="assigned_to"
                                                defaultValue={selected?.assignedTo}
                                                getOptionValue={option => option.id}
                                                getOptionLabel={option => option.name}
                                                onChange={handleSelect}
                                                className="assigned_to"
                                                placeholder="Select"
                                                options={selectOptions?.assignedToOptions}
                                             />
                                             : <Form.Control
                                                readOnly={true}
                                                plaintext={true}
                                                name='assigned_to'
                                                value={`${formData?.assigned_to?.first_name || ''} ${formData?.assigned_to?.last_name || ''}`}
                                             />
                                      }
                                  </Col>
                              </Form.Group>
                           }
                       </Col>
                   </Row>
                   <Row>
                       <Col>
                           {
                              user.is_employee &&
                              <Form.Group as={Row}>
                                  <Form.Label column sm={2}>
                                      Comments
                                  </Form.Label>
                                  <Col sm={10}>
                                      <Form.Control
                                         as='textarea'
                                         readOnly={!editMode}
                                         plaintext={!editMode}
                                         onChange={handleChange}
                                         name="comment"
                                         value={formData?.comment}
                                      />
                                  </Col>
                              </Form.Group>
                           }
                       </Col>
                   </Row>
                   <Row>
                       <Col>
                           {
                              user.is_employee &&
                              <Form.Group as={Row}>
                                  <Form.Label column sm={2}>
                                      Request Type
                                  </Form.Label>
                                  <Col sm={10}>
                                      {
                                          editMode
                                             ? <Select
                                                isSearchable
                                                name="request_type"
                                                defaultValue={selected.requestType}
                                                getOptionValue={option => option.id}
                                                getOptionLabel={option => option.name}
                                                onChange={handleSelect}
                                                className="request_type"
                                                placeholder="Select"
                                                options={selectOptions?.requestTypeOptions}
                                             />
                                             : <Form.Control
                                                readOnly={true}
                                                plaintext={true}
                                                name='request_type'
                                                value={formData?.request_type || ''}
                                             />
                                      }
                                  </Col>
                              </Form.Group>
                           }
                       </Col>
                   </Row>
               </Form>

           </Card.Body>
       </Card>
    )
}

export default UserRequestForm;