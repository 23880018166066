import http from "./httpService";
import floatFormatter from '../components/Custom/floatFormatter.js'
import {fieldTypeContact} from "../config/contact/fieldTypeContact.js"
// Import Endpoints
import {
    walletUrl,
    loanLedgerUrl,
    masterLedgerUrlV1, paymentsUrlV1, chargesUrlV1,
} from '../config/Endpoints';
import {patchData, putData} from "./endpointService";

const getBills = async (id)=> {
    try {
        const {data} = await http.get(`${masterLedgerUrlV1}?payee_contact_id=${id}`)
        return floatFormatter(data, fieldTypeContact)
    } catch (error) {
        console.log(error)
    }
}

const getBillsForAny=async(id,parameter,query)=>{
    let st= "";
    if(query){
        st=query
    }
    try {
        const {data} = await http.get(`${masterLedgerUrlV1}?${parameter}=${id}&search=${st}`)
        return floatFormatter(data, fieldTypeContact)
    } catch (error) {
        console.log(error)
    }
}

const getloanLedger=async(id)=> {
    try {
        const {data}=await http.get(`${loanLedgerUrl+id}/`)
        return floatFormatter(data, fieldTypeContact)
    } catch (error) {
        console.log(error)
    }
}

const getBillsForTrust = async (id,lineChoice='misc')=>{
    try {
        const {data} = await http.get(`${masterLedgerUrlV1}?trust_id=${id}&related_bill_type=${lineChoice}`)
        console.log(data)
        return data
        // return floatFormatter(data, fieldTypeContact)
    } catch (error) {
        console.log(error)
    }
}

const getOptions = async() => {
    try {
        const {data} = await  http.options(`${masterLedgerUrlV1}`)
        return data
      } catch (error) {
          console.log(error)
      }
}

const updateBill = async (id,obj) => {

    await patchData(chargesUrlV1,id,obj)

}

const createBill = async (obj)=> {
    try {
        const {data} = await http.post(`${masterLedgerUrlV1}`, obj)
        return data
    } catch (error) {
        console.log(error)
    }
}

const deleteBill=async(id)=>{
    try {
        const {data} = await http.delete(`${masterLedgerUrlV1}${id}/`)
        return data
    } catch (error) {
        console.log(error)
    }
}

export default {
    getBills,
    createBill,
    getOptions,
    updateBill,
    deleteBill,
    getBillsForTrust,
    getBillsForAny,
    getloanLedger,
}